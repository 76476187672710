import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QRDrawer from "qrcode.react";
import PrintProvider, { Print } from "react-easy-print";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { GQLClient } from "entities/client";

interface QRCodeProps {
  data: GQLClient;
  refetch: any;
}

const ONE_PX_IN_CM = 0.026458;
const MARGIN = 0.5;

export const QRCode: React.FC<QRCodeProps> = ({ data }) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(6);
  const [total, setTotal] = useState(1);
  const calcSize = () => (size - MARGIN * 2) / ONE_PX_IN_CM;
  const [qrCodeSize, setQRCodeSize] = useState(calcSize());

  const publicLink =
    data.public && data.uniqueName
      ? `${process.env.REACT_APP_QR_LINK}${data.uniqueName}`
      : `${process.env.REACT_APP_QR_LINK}${data.uid}`;
  const qrLink = `${process.env.REACT_APP_QR_LINK}${data.uid}`;
  const changeSize = () => setQRCodeSize(calcSize());

  return (
    <PrintProvider>
      <div className="p-grid">
        <h3 className="p-col-12">{t("QRCodePage.Subtitle")}</h3>

        <p className="p-col-12">{t("QRCodePage.Explanation")}</p>
        <ul className="p-col-12 qr-code-print-options">
          <li>{t("QRCodePage.Option1")}</li>
          <li>{t("QRCodePage.Option2")}</li>
          <li>{t("QRCodePage.Option3")}</li>
        </ul>
        <h3 className="p-col-12">{t("QRCodePage.Label")}</h3>
        <div className="p-col-12">
          {t("QRCodePage.Total")}:{" "}
          <InputText
            value={total}
            onChange={(e: any) => setTotal(e.target.value)}
          />
        </div>
        <div className="p-col-12">
          {t("QRCodePage.Size")}:{" "}
          <InputText
            value={size}
            onChange={(e: any) => setSize(e.target.value)}
          />
        </div>
        <div className="p-col-12">
          <Button
            label={t("QRCodePage.ChangeSize")}
            icon="pi pi-refresh"
            onClick={changeSize}
          />
        </div>
        <div className="p-col-12 label-container">
          <Print single name="label">
            <div
              className="klivr-label"
              style={{
                width: `${size / ONE_PX_IN_CM}px`,
              }}
            >
              <p>{t("QRCodePage.ScanForMenu")}</p>
              <QRDrawer value={qrLink} size={qrCodeSize} />
              <p>{publicLink.replace("https://", "")}</p>
            </div>

            {Array.from(Array((total || 1) - 1)).map((_, index) => (
              <div
                className="klivr-label print-only"
                style={{
                  width: `${size / ONE_PX_IN_CM}px`,
                }}
                key={index}
              >
                <p>{t("QRCodePage.ScanForMenu")}</p>
                <QRDrawer value={qrLink} size={qrCodeSize} />
                <p>{publicLink.replace("https://", "")}</p>
              </div>
            ))}
          </Print>
        </div>
        <div className="p-col-12">
          <Button
            label={t("QRCodePage.Print")}
            icon="pi pi-print"
            onClick={() => window.print()}
          />
        </div>
      </div>
    </PrintProvider>
  );
};
