import React from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";

import {
  UPDATE_PRODUCT_AVAILABLE_MUTATION,
  UPDATE_PRODUCT_MAIN_PAGE_MUTATION,
  UPDATE_PRODUCT_VISIBILITY_MUTATION,
} from "graphql/mutations/products";
import { switchComponent } from "utils/components";
import { GQLProduct } from "entities/product";
import { defaultLanguageState } from "atoms/defaultLanguage";
import { useRecoilValue } from "recoil";

interface OrdersProps {
  data: GQLProduct[];
  refetch: any;
}

const Menu: React.FC<OrdersProps> = ({ data, refetch }) => {
  let growl: any;
  const { t } = useTranslation();
  const defaultLanguage = useRecoilValue(defaultLanguageState);
  const productUpdated = ({ product }) => {
    if (!product.id) {
      growl.show({
        severity: "error",
        summary: t("MenuPage.Error.Updating"),
      });
    } else {
      refetch();
    }
  };

  const [mutationAvailable] = useMutation(UPDATE_PRODUCT_AVAILABLE_MUTATION, {
    onError: () => productUpdated({ product: {} }),
    onCompleted: ({ updateProduct }) => productUpdated(updateProduct),
  });

  const [mutationMainPage] = useMutation(UPDATE_PRODUCT_MAIN_PAGE_MUTATION, {
    onError: () => productUpdated({ product: {} }),
    onCompleted: ({ updateProduct }) => productUpdated(updateProduct),
  });

  const [mutationHide] = useMutation(UPDATE_PRODUCT_VISIBILITY_MUTATION, {
    onError: () => productUpdated({ product: {} }),
    onCompleted: ({ updateProduct }) => productUpdated(updateProduct),
  });

  return (
    <div className="card card-w-title">
      <Growl ref={(el) => (growl = el)} />
      <DataTable responsive={true} value={data} style={{ textAlign: "center" }}>
        {defaultLanguage === "en" ? (
          <Column field="name_en" header={t("MenuPage.Name")} />
        ) : null}
        {defaultLanguage === "en" ? (
          <Column field="description_en" header={t("MenuPage.Description")} />
        ) : null}
        {defaultLanguage === "es" ? (
          <Column field="name_es" header={t("MenuPage.Name")} />
        ) : null}
        {defaultLanguage === "es" ? (
          <Column field="description_es" header={t("MenuPage.Description")} />
        ) : null}
        {defaultLanguage === "ca" ? (
          <Column field="name_ca" header={t("MenuPage.Name")} />
        ) : null}
        {defaultLanguage === "ca" ? (
          <Column field="description_ca" header={t("MenuPage.Description")} />
        ) : null}
        <Column
          field="mainPage"
          header={t("MenuPage.MainPage")}
          body={(rowData: any) =>
            switchComponent(rowData.mainPage, (e) => {
              if (e.value) {
                growl.show({
                  severity: "info",
                  summary: t("MenuPage.MainPageWarning"),
                });
              }
              mutationMainPage({
                variables: {
                  productId: rowData.id,
                  isMainPage: e.value,
                },
              });
            })
          }
        />
        <Column
          field="hidden"
          header={t("MenuPage.Hidden")}
          body={(rowData: any) =>
            switchComponent(rowData.hidden, (e) =>
              mutationHide({
                variables: {
                  productId: rowData.id,
                  isHidden: e.value,
                },
              })
            )
          }
        />
        <Column
          field="available"
          header={t("MenuPage.Available")}
          body={(rowData: any) =>
            switchComponent(rowData.available, (e) =>
              mutationAvailable({
                variables: {
                  productId: rowData.id,
                  available: e.value,
                },
              })
            )
          }
        />
      </DataTable>
    </div>
  );
};

export default Menu;
