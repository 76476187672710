import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLUser } from "entities/user";

import MY_ACCOUNT_QUERY from "graphql/queries/users/myAccount";
import { MyAccount } from "components/MyAccount";

const MyAccountContainer = () => {
  return (
    <Query query={MY_ACCOUNT_QUERY}>
      {({ data: { user }, refetch }: QueryResponse<GQLUser>) => {
        return <MyAccount data={user as any} refetch={refetch} />;
      }}
    </Query>
  );
};

export default MyAccountContainer;
