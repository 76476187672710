import gql from "graphql-tag";

export const MY_ACCOUNT_PASSWORD_UPDATE_MUTATION = gql`
  mutation UpdateMyAccount(
    $email: String
    $password: String
    $emailNews: Boolean
    $emailUpdates: Boolean
  ) {
    updateUser(
      input: {
        data: {
          email: $email
          password: $password
          emailNews: $emailNews
          emailUpdates: $emailUpdates
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const MY_ACCOUNT_UPDATE_MUTATION = gql`
  mutation UpdateMyAccount(
    $email: String
    $emailNews: Boolean
    $emailUpdates: Boolean
  ) {
    updateUser(
      input: {
        data: {
          email: $email
          emailNews: $emailNews
          emailUpdates: $emailUpdates
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;
