import React, { useState, useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { GQLSettings } from "entities/settings";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import {
  SETTINGS_CREATE_MUTATION,
  SETTINGS_UPDATE_MUTATION,
} from "graphql/mutations/settings";
import { SUPPORTED_LANGUAGES } from "config/constants";
import { defaultLanguageState } from "atoms/defaultLanguage";
import { languagesState } from "atoms/languages";
import { GQLClient } from "entities/client";
import Api from "api";

interface SettingsProps {
  data: GQLSettings;
  client: GQLClient;
  refetch: any;
}

export const Settings: React.FC<SettingsProps> = ({
  data,
  refetch,
  client,
}) => {
  const { t } = useTranslation();
  const {
    id: originalId,
    en: originalEN,
    es: originalES,
    ca: originalCA,
    defaultLanguage: originalDefaultLanguage,
  } = data || ({} as GQLSettings);

  const [settingsId, setSettingsId] = useState(originalId || null);
  const [publicDomain, setPublicDomain] = useState(client.public);
  const [errorUniqueName, setErrorUniqueName] = useState(false);
  const [uniqueName, setUniqueName] = useState(client.uniqueName);
  const [en, setEN] = useState(!!originalEN);
  const [es, setES] = useState(!!originalES);
  const [ca, setCA] = useState(!!originalCA);
  const [defaultLanguage, setDefaultLanguage] = useState(
    originalDefaultLanguage || "en"
  );
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const setGlobalDefaultLanguage = useSetRecoilState(defaultLanguageState);
  const setLanguages = useSetRecoilState(languagesState);

  const invalidSettings = useCallback(() => {
    return !en && !es && !ca;
  }, [es, en, ca]);

  useEffect(() => {
    if (invalidSettings()) {
      setEN(true);
      setDefaultLanguage("en");
    }
  }, [invalidSettings, setEN, setDefaultLanguage]);

  const enableLanguages = {
    en: setEN,
    es: setES,
    ca: setCA,
  };

  const updated = (status: any) => {
    if (status && status.setting && status.setting.id) {
      setSettingsId(status.setting.id);
      setMessage(t("SettingsPage.Msgs.Updated"));
      setGlobalDefaultLanguage(defaultLanguage);
      setLanguages({
        en,
        es,
        ca,
        loaded: true,
      });
      setError(false);
      refetch();
    } else {
      setError(true);
      setMessage(t("SettingsPage.Msgs.Error"));
    }
    window.scrollTo(0, 0);
  };

  const changeLanguage = (e) => {
    setDefaultLanguage(e.value);
    enableLanguages[e.value](true);
  };

  const changeValue = (setFunction: any) => (e: any) => {
    setFunction(e.value || e.target.value);
  };

  const changeUniqueName = async (e: any) => {
    setErrorUniqueName(false);
    setUniqueName(e.target.value);
  };

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">{t("SettingsPage.DefaultLanguage")}</h3>
        <div className="p-col-12 p-md-2">
          <Dropdown
            value={defaultLanguage}
            options={SUPPORTED_LANGUAGES}
            onChange={changeLanguage}
            placeholder={t("SettingsPage.SelectDefaultLanguage")}
          />
        </div>
        <h3 className="p-col-12">{t("SettingsPage.Languages")}</h3>
        <div className="p-col-12 p-lg-1">
          <div>{t("SettingsPage.English")}</div>
          <InputSwitch checked={en} onChange={changeValue(setEN)} />
        </div>
        <div className="p-col-12 p-lg-1">
          <div>{t("SettingsPage.Spanish")}</div>
          <InputSwitch checked={es} onChange={changeValue(setES)} />
        </div>
        <div className="p-col-12 p-lg-1">
          <div>{t("SettingsPage.Catalan")}</div>
          <InputSwitch checked={ca} onChange={changeValue(setCA)} />
        </div>

        <h3 className="p-col-12">{t("SettingsPage.PublicDomain")}</h3>
        <div className="p-col-12">
          <div>{t("SettingsPage.MakeItPublic")}</div>
          <InputSwitch
            checked={publicDomain}
            onChange={changeValue(setPublicDomain)}
          />
        </div>
        <div className="p-col-12 p-lg-4">
          <div>{t("SettingsPage.UniqueName")}</div>
          <InputText value={uniqueName} onChange={changeUniqueName} />
        </div>
        {errorUniqueName ? (
          <div className="p-col-12 error">
            <div>{t("SettingsPage.Error.NameIsNotUnique")}</div>
          </div>
        ) : null}

        {invalidSettings() ? (
          <div className="p-col-12">
            {t("SettingsPage.Msgs.SelectOneLanguage")}
          </div>
        ) : null}
        <div className="p-col-12">
          <div className="p-col-12 p-lg-3">
            <Mutation
              mutation={
                settingsId ? SETTINGS_UPDATE_MUTATION : SETTINGS_CREATE_MUTATION
              }
              variables={{
                settingsId,
                en,
                es,
                ca,
                defaultLanguage,
                uniqueName,
                publicDomain,
              }}
              onCompleted={({ createSetting, updateSetting }: any) =>
                updated(createSetting || updateSetting)
              }
            >
              {(mutation: any) => (
                <Button
                  label={t("Save")}
                  disabled={invalidSettings()}
                  icon="pi pi-pencil"
                  onClick={async () => {
                    const isUnique = await Api.checkUniqueName(uniqueName);
                    if (isUnique) {
                      mutation();
                    } else {
                      setErrorUniqueName(true);
                    }
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
