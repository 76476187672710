import gql from "graphql-tag";

export const PRODUCTS_CREATE_MUTATION = gql`
  mutation CreateProduct(
    $nameEN: String
    $nameES: String
    $nameCA: String
    $descriptionEN: String
    $descriptionES: String
    $descriptionCA: String
    $price: String
    $available: Boolean
    $categories: [ID]
    $productAttributes: [ID]
    $highlight: Boolean
    $isNew: Boolean
    $isHidden: Boolean
    $isMainPage: Boolean
    $order: Int
    $multiplePrice: Boolean
    $multiplePrices: [ID]
  ) {
    createProduct(
      input: {
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          price: $price
          available: $available
          highlight: $highlight
          new: $isNew
          mainPage: $isMainPage
          hidden: $isHidden
          product_attributes: $productAttributes
          categories: $categories
          order: $order
          multiPrice: $multiplePrice
          product_prices: $multiplePrices
        }
      }
    ) {
      product {
        id
      }
    }
  }
`;

export const PRODUCTS_UPDATE_MUTATION = gql`
  mutation UpdateProduct(
    $productId: ID!
    $nameEN: String
    $nameES: String
    $nameCA: String
    $descriptionEN: String
    $descriptionES: String
    $descriptionCA: String
    $price: String
    $available: Boolean
    $highlight: Boolean
    $productAttributes: [ID]
    $categories: [ID]
    $order: Int
    $isNew: Boolean
    $isHidden: Boolean
    $isMainPage: Boolean
    $multiplePrice: Boolean
    $multiplePrices: [ID]
  ) {
    updateProduct(
      input: {
        where: { id: $productId }
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          price: $price
          available: $available
          highlight: $highlight
          new: $isNew
          mainPage: $isMainPage
          hidden: $isHidden
          product_attributes: $productAttributes
          categories: $categories
          order: $order
          multiPrice: $multiplePrice
          product_prices: $multiplePrices
        }
      }
    ) {
      product {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_AVAILABLE_MUTATION = gql`
  mutation UpdateProduct($productId: ID!, $available: Boolean) {
    updateProduct(
      input: { where: { id: $productId }, data: { available: $available } }
    ) {
      product {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_VISIBILITY_MUTATION = gql`
  mutation UpdateProduct($productId: ID!, $isHidden: Boolean) {
    updateProduct(
      input: { where: { id: $productId }, data: { hidden: $isHidden } }
    ) {
      product {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_MAIN_PAGE_MUTATION = gql`
  mutation UpdateProduct($productId: ID!, $isMainPage: Boolean) {
    updateProduct(
      input: { where: { id: $productId }, data: { mainPage: $isMainPage } }
    ) {
      product {
        id
      }
    }
  }
`;

export const PRODUCTS_DELETE_MUTATION = gql`
  mutation DeleteProduct($productId: ID!) {
    deleteProduct(input: { where: { id: $productId } }) {
      product {
        id
      }
    }
  }
`;
