export interface IMenuOption {
  label: string;
  className?: string;
  icon: string;
  badge?: number;
  badgeStyleClass?: string;
  command?: () => any;
  to?: string;
  items?: IMenuOption[];
  refresh?: boolean;
  roles?: string[];
  visible?: (roles, client) => boolean;
}

export const SUPER_ADMIN = "SUAD";
export const SUPER_USER = "SUAD";
export const CLIENT_USER = "CLUS";

export const visibleIfClient = (user, client) => {
  return !!client;
};

export type IMenu = IMenuOption[];

export const MAIN_MENU_OPTIONS: IMenu = [
  {
    label: "Stores",
    icon: "pi pi-fw pi-home",
    to: "/stores",
    className: "tour-stores",
    roles: [SUPER_ADMIN],
  },
  // TODO: This will be enabled for premium
  // {
  //   label: "Users",
  //   icon: "pi pi-fw pi-users",
  //   to: "/",
  //   className: "tour-users",
  //   roles: [SUPER_ADMIN],
  // },
];
