import React, { useState } from "react";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import ReactCrop from "react-image-crop";

import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

import { GQLBranding } from "entities/branding";
import { GQLColorSchema } from "entities/colorSchema";
import { GQLCurrency } from "entities/currency";

import {
  BRANDING_CREATE_MUTATION,
  BRANDING_UPDATE_MUTATION,
} from "graphql/mutations/branding";
import { Auth } from "utils/auth";

import { ORIGINAL_COLOR_SCHEMA } from "config/constants";
import { languagesState } from "atoms/languages";
import { Dialog } from "primereact/dialog";
import { GQLClient } from "entities/client";

import "react-image-crop/lib/ReactCrop.scss";

interface BrandingProps {
  data: GQLBranding;
  colorSchemas: GQLColorSchema[];
  currencies: GQLCurrency[];
  client: GQLClient;
  refetch: any;
}

export const Branding: React.FC<BrandingProps> = ({
  data,
  colorSchemas,
  currencies,
  client,
  refetch,
}) => {
  const { t } = useTranslation();
  const selectedLanguages = useRecoilValue(languagesState);
  const {
    id: originalId,
    title_en: originalTitleEN,
    title_es: originalTitleES,
    title_ca: originalTitleCA,
    description_en: originalDescriptionEN,
    description_es: originalDescriptionES,
    description_ca: originalDescriptionCA,
    otherInformation_en: originalOtherInformationEN,
    otherInformation_es: originalOtherInformationES,
    otherInformation_ca: originalOtherInformationCA,
    currency: originalCurrency,
    logo: originalLogo,
    web: originalWeb,
    facebook: originalFacebook,
    instagram: originalInstagram,
    tripAdvisor: originalTripAdvisor,
    googleMapsLink: originalGoogleMapsLink,
    whatsapp: originalWhatsapp,
    whatsappOrder: originalWhatsappOrder,
    wifiName: originalWifiName,
    wifiPassword: originalWifiPassword,
    metaTagImage: originalMetaTagImage,
    metaTagTitle: originalMetaTagTitle,
    metaTagDescription: originalMetaTagDescription,
    customColor: originalCustomColor,
    colorSchema: originalColorSchema,
    headerSectionBackgroundColor: originalHeaderSectionBackgroundColor,
    headerSectionTextColor: originalHeaderSectionTextColor,
    mainMenuBackgroundColor: originalMainMenuBackgroundColor,
    mainMenuTextColor: originalMainMenuTextColor,
    categoryMenuBackgroundColor: originalCategoryMenuBackgroundColor,
    categoryMenuTextColor: originalCategoryMenuTextColor,
    productBackgroundColor: originalProductBackgroundColor,
    backgroundHighlightedProductColor: originalBackgroundHighlightedProductColor,
    labelBoxBackgroundColor: originalLabelBoxBackgroundColor,
    labelBoxTextColor: originalLabelBoxTextColor,
    notAvailableBackgroundColor: originalNotAvailableBackgroundColor,
    notAvailableTextColor: originalNotAvailableTextColor,
    textTitleColor: originalTextTitleColor,
    textDescriptionColor: originalTextDescriptionColor,
    priceBackgroundColor: originalPriceBackgroundColor,
    textPriceColor: originalTextPriceColor,
    popupBackgroundColor: originalPopupBackgroundColor,
    generalTextColor: originalGeneralTextColor,
    topBackgroundColor: originalTopBackgroundColor,
    bottomBackgroundColor: originalBottomBackgroundColor,
    headerBackgroundColor: originalHeaderBackgroundColor,
    headerFontColor: originalHeaderFontColor,
    backgroundLightyColor: originalBackgroundLightyColor,
  } = data || ({} as GQLBranding);

  const [imageRef, setImageRef] = useState<any>(null);
  const [originalFile, setOriginalFile] = useState<any>(null);

  const [metaTagImageRef, setMetaTagImageRef] = useState<any>(null);
  const [originalImage, setOriginalImage] = useState<any>(null);

  const [cropLogo, setCropLogo] = useState<any>({
    src: null,
    crop: {
      unit: "%",
      width: 30,
    },
    croppedImageUrl: null,
  });

  const [cropMetaImage, setCropMetaImage] = useState<any>({
    src: null,
    crop: {
      unit: "%",
      width: 30,
    },
    croppedImageUrl: null,
  });

  const [brandingId, setBrandingId] = useState(originalId || null);
  const [titleEN, setTitleEN] = useState(originalTitleEN || "");
  const [titleES, setTitleES] = useState(originalTitleES || "");
  const [titleCA, setTitleCA] = useState(originalTitleCA || "");
  const [descriptionEN, setDescriptionEN] = useState(
    originalDescriptionEN || ""
  );
  const [descriptionES, setDescriptionES] = useState(
    originalDescriptionES || ""
  );
  const [descriptionCA, setDescriptionCA] = useState(
    originalDescriptionCA || ""
  );
  const [otherInformationEN, setOtherInformationEN] = useState(
    originalOtherInformationEN || ""
  );
  const [otherInformationES, setOtherInformationES] = useState(
    originalOtherInformationES || ""
  );
  const [otherInformationCA, setOtherInformationCA] = useState(
    originalOtherInformationCA || ""
  );
  const [selectedSchema, setSelectedSchema] = useState<string | null>(
    originalColorSchema?.id
  );
  const [showSchemaImage, setShowSchemaImage] = useState(false);
  const [customSchema, setCustomSchema] = useState(
    originalCustomColor || false
  );
  const [currency, setCurrency] = useState(originalCurrency || "");
  const [wifiName, setWifiName] = useState(originalWifiName || "");
  const [wifiPassword, setWifiPassword] = useState(originalWifiPassword || "");
  const [web, setWeb] = useState(originalWeb || "");
  const [facebook, setFacebook] = useState(originalFacebook || "");
  const [instagram, setInstagram] = useState(originalInstagram || "");
  const [tripAdvisor, setTripAdvisor] = useState(originalTripAdvisor || "");
  const [googleMapsLink, setGoogleMapsLink] = useState(
    originalGoogleMapsLink || ""
  );
  const [whatsapp, setWhatsapp] = useState(originalWhatsapp || "");
  const [whatsappOrder, setWhatsappOrder] = useState(
    originalWhatsappOrder || false
  );
  const [metaTagTitle, setMetaTagTitle] = useState(originalMetaTagTitle || "");
  const [metaTagDescription, setMetaTagDescription] = useState(
    originalMetaTagDescription || ""
  );

  const [
    headerSectionBackgroundColor,
    setHeaderSectionBackgroundColor,
  ] = useState(
    originalHeaderSectionBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.headerSectionBackgroundColor
  );
  const [headerSectionTextColor, setHeaderSectionTextColor] = useState(
    originalHeaderSectionTextColor ||
      ORIGINAL_COLOR_SCHEMA.headerSectionTextColor
  );
  const [mainMenuBackgroundColor, setMainMenuBackgroundColor] = useState(
    originalMainMenuBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.mainMenuBackgroundColor
  );
  const [mainMenuTextColor, setMainMenuTextColor] = useState(
    originalMainMenuTextColor || ORIGINAL_COLOR_SCHEMA.mainMenuTextColor
  );
  const [
    categoryMenuBackgroundColor,
    setCategoryMenuBackgroundColor,
  ] = useState(
    originalCategoryMenuBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.categoryMenuBackgroundColor
  );
  const [categoryMenuTextColor, setCategoryMenuTextColor] = useState(
    originalCategoryMenuTextColor || ORIGINAL_COLOR_SCHEMA.categoryMenuTextColor
  );
  const [productBackgroundColor, setProductBackgroundColor] = useState(
    originalProductBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.productBackgroundColor
  );
  const [labelBoxBackgroundColor, setLabelBoxBackgroundColor] = useState(
    originalLabelBoxBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.labelBoxBackgroundColor
  );
  const [labelBoxTextColor, setLabelBoxTextColor] = useState(
    originalLabelBoxTextColor || ORIGINAL_COLOR_SCHEMA.labelBoxTextColor
  );
  const [
    notAvailableBackgroundColor,
    setNotAvailableBackgroundColor,
  ] = useState(
    originalNotAvailableBackgroundColor ||
      ORIGINAL_COLOR_SCHEMA.notAvailableBackgroundColor
  );
  const [notAvailableTextColor, setNotAvailableTextColor] = useState(
    originalNotAvailableTextColor || ORIGINAL_COLOR_SCHEMA.notAvailableTextColor
  );
  const [priceBackgroundColor, setPriceBackgroundColor] = useState(
    originalPriceBackgroundColor || ORIGINAL_COLOR_SCHEMA.priceBackgroundColor
  );
  const [popupBackgroundColor, setPopupBackgroundColor] = useState(
    originalPopupBackgroundColor || ORIGINAL_COLOR_SCHEMA.popupBackgroundColor
  );
  const [backgroundLightyColor, setBackgroundLightyColor] = useState(
    originalBackgroundLightyColor || ORIGINAL_COLOR_SCHEMA.backgroundLightyColor
  );
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(
    originalHeaderBackgroundColor || ORIGINAL_COLOR_SCHEMA.headerBackgroundColor
  );
  const [headerFontColor, setHeaderFontColor] = useState(
    originalHeaderFontColor || ORIGINAL_COLOR_SCHEMA.headerFontColor
  );
  const [topBackgroundColor, setTopBackgroundColor] = useState(
    originalTopBackgroundColor || ORIGINAL_COLOR_SCHEMA.topBackgroundColor
  );
  const [bottomBackgroundColor, setBottomBackgroundColor] = useState(
    originalBottomBackgroundColor || ORIGINAL_COLOR_SCHEMA.bottomBackgroundColor
  );
  const [generalTextColor, setGeneralTextColor] = useState(
    originalGeneralTextColor || ORIGINAL_COLOR_SCHEMA.generalTextColor
  );
  const [textTitleColor, setTextTitleColor] = useState(
    originalTextTitleColor || ORIGINAL_COLOR_SCHEMA.textTitleColor
  );
  const [textDescriptionColor, setTextDescriptionColor] = useState(
    originalTextDescriptionColor || ORIGINAL_COLOR_SCHEMA.textDescriptionColor
  );
  const [textPriceColor, setTextPriceColor] = useState(
    originalTextPriceColor || ORIGINAL_COLOR_SCHEMA.textPriceColor
  );
  const [
    backgroundHighlightedProductColor,
    setBackgroundHighlightedProductColor,
  ] = useState(
    originalBackgroundHighlightedProductColor ||
      ORIGINAL_COLOR_SCHEMA.backgroundHighlightedProductColor
  );

  const [pendingFiles, setPendingFiles] = useState<any>(null);
  const [pendingMetaTagImage, setPendingMetaTagImage] = useState<any>(null);
  const [logo] = useState(originalLogo);
  const [metaTagImage] = useState<any>(originalMetaTagImage);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const updated = (createBranding: any) => {
    if (
      createBranding &&
      createBranding.branding &&
      createBranding.branding.id
    ) {
      setBrandingId(createBranding.branding.id);
      setMessage(t("BrandingPage.Msgs.Updated"));

      if (pendingFiles || pendingMetaTagImage) {
        if (pendingMetaTagImage) {
          const formDataMetaTag = new FormData();
          formDataMetaTag.set("ref", "branding");
          formDataMetaTag.set("field", "metaTagImage");
          const file = pendingMetaTagImage[0];
          formDataMetaTag.append("files", file, file.name);
          formDataMetaTag.set("refId", createBranding.branding.id);
          const token = Auth.getToken();
          Axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
            data: formDataMetaTag,
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: token ? `Bearer ${token}` : "",
            },
          }).finally(async () => {
            setPendingMetaTagImage(null);
            if (!pendingFiles) {
              window.setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
          });
        }

        if (pendingFiles) {
          const formData = new FormData();
          formData.set("ref", "branding");
          formData.set("field", "logo");
          const file = pendingFiles[0];
          formData.append("files", file, file.name);
          formData.set("refId", createBranding.branding.id);
          const token = Auth.getToken();
          Axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: token ? `Bearer ${token}` : "",
            },
          }).finally(async () => {
            setPendingFiles(null);
            window.setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
        }
      } else {
        refetch();
        setError(false);
      }
    } else {
      setError(true);
      setMessage(t("BrandingPage.Msgs.Error"));
    }
    window.scrollTo(0, 0);
  };

  const currentImage = originalLogo ? originalLogo.url : "";
  const currentMetaTagImage = originalMetaTagImage
    ? originalMetaTagImage.url
    : "";

  const fixUrl = (url: string = "") => {
    const fixedUrl = url
      .toLowerCase()
      .replace("https://", "")
      .replace("http://", "");
    return fixedUrl.length ? `https://${fixedUrl}` : "";
  };

  const onChange = (setFunction: any) => (e: any) => {
    setFunction(e.target.value);
  };

  const setImageFormData = (event: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setCropMetaImage({ ...cropMetaImage, src: fileReader.result });
    };
    setOriginalImage(event.files[0]);
    fileReader.readAsDataURL(event.files[0]);
  };

  const setFormData = (event: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setCropLogo({ ...cropLogo, src: fileReader.result });
    };
    setOriginalFile(event.files[0]);
    fileReader.readAsDataURL(event.files[0]);
  };

  const updateColorSchema = (id: string) => {
    setSelectedSchema(id);
  };

  const updateColors = () => {
    const selectedColors = colorSchemas.find(
      (colors) => colors.id === selectedSchema
    );

    setHeaderSectionBackgroundColor(
      selectedColors.headerSectionBackgroundColor || ""
    );
    setHeaderSectionTextColor(selectedColors.headerSectionTextColor || "");
    setMainMenuBackgroundColor(selectedColors.mainMenuBackgroundColor || "");
    setMainMenuTextColor(selectedColors.mainMenuTextColor || "");
    setCategoryMenuBackgroundColor(
      selectedColors.categoryMenuBackgroundColor || ""
    );
    setCategoryMenuTextColor(selectedColors.categoryMenuTextColor || "");
    setProductBackgroundColor(selectedColors.productBackgroundColor || "");
    setBackgroundHighlightedProductColor(
      selectedColors.backgroundHighlightedProductColor || ""
    );
    setLabelBoxBackgroundColor(selectedColors.labelBoxBackgroundColor || "");
    setLabelBoxTextColor(selectedColors.labelBoxTextColor || "");
    setNotAvailableBackgroundColor(
      selectedColors.notAvailableBackgroundColor || ""
    );
    setNotAvailableTextColor(selectedColors.notAvailableTextColor || "");
    setTextTitleColor(selectedColors.textTitleColor || "");
    setTextDescriptionColor(selectedColors.textDescriptionColor || "");
    setPriceBackgroundColor(selectedColors.priceBackgroundColor || "");
    setTextPriceColor(selectedColors.textPriceColor || "");
    setPopupBackgroundColor(selectedColors.popupBackgroundColor || "");
    setGeneralTextColor(selectedColors.generalTextColor || "");
    setTopBackgroundColor(selectedColors.topBackgroundColor || "");
    setBottomBackgroundColor(selectedColors.bottomBackgroundColor || "");
    setHeaderBackgroundColor(selectedColors.headerBackgroundColor || "");
    setHeaderFontColor(selectedColors.headerFontColor || "");
    setBackgroundLightyColor(selectedColors.backgroundLightyColor || "");
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onMetaImageLoaded = (image) => {
    setMetaTagImageRef(image);
  };

  const onCropChange = (crop) => {
    setCropLogo({ ...cropLogo, crop });
  };

  const onCropMetaImageChange = (crop) => {
    setCropMetaImage({ ...cropMetaImage, crop });
  };

  const onCropComplete = (crop) => {
    if (imageRef && cropLogo.crop.width && cropLogo.crop.height) {
      const croppedImageUrl = getCroppedImg(
        setCropLogo,
        cropLogo,
        setPendingFiles,
        originalFile
      )(imageRef, crop);
      setCropLogo({ ...cropLogo, croppedImageUrl });
    }
  };

  const onCropMetaImageComplete = (crop) => {
    if (
      metaTagImageRef &&
      cropMetaImage.crop.width &&
      cropMetaImage.crop.height
    ) {
      const croppedImageUrl = getCroppedImg(
        setCropMetaImage,
        cropMetaImage,
        setPendingMetaTagImage,
        originalImage
      )(metaTagImageRef, crop);
      setCropMetaImage({ ...cropMetaImage, croppedImageUrl });
    }
  };

  const { standard, premium } = client.adminUser || {
    standard: false,
    premium: false,
  };

  const getCroppedImg = (
    setCrop,
    originalCrop,
    setPending,
    originalFileOrImage
  ) => (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(
          setCrop,
          originalCrop,
          setPending
        )(reader.result, originalFileOrImage.name);
      };
    });
  };

  const dataURLtoFile = (setCrop, crop, setPending) => (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCrop({ ...crop, croppedImage });
    setPending([croppedImage]);
  };

  const selectedColorSchema = colorSchemas.find(
    (colors) => colors.id === selectedSchema
  );

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">{t("BrandingPage.Information")}</h3>
        <Dialog
          header={t("Sample")}
          visible={showSchemaImage}
          onHide={() => {
            setShowSchemaImage(false);
          }}
        >
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}${
              selectedColorSchema?.previewImage
                ? selectedColorSchema.previewImage.url
                : "invalid"
            }`}
            alt="Error"
          />
        </Dialog>

        {selectedLanguages.en ? (
          <Accordion className="p-col-12">
            <AccordionTab header="English">
              <div className="p-col-12 p-md-2">
                <label htmlFor="titleEN">{t("BrandingPage.Title")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="titleEN"
                  onChange={onChange(setTitleEN)}
                  value={titleEN}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="descriptionEN">
                  {t("BrandingPage.Description")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="descriptionEN"
                  onChange={onChange(setDescriptionEN)}
                  value={descriptionEN}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="otherInformationEN">
                  {t("BrandingPage.OtherInformation")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="otherInformationEN"
                  onChange={onChange(setOtherInformationEN)}
                  value={otherInformationEN}
                />
              </div>
            </AccordionTab>
          </Accordion>
        ) : null}
        {selectedLanguages.es ? (
          <Accordion className="p-col-12">
            <AccordionTab header="Español">
              <div className="p-col-12 p-md-2">
                <label htmlFor="titleES">{t("BrandingPage.Title")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="titleES"
                  onChange={onChange(setTitleES)}
                  value={titleES}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="descriptionES">
                  {t("BrandingPage.Description")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="descriptionES"
                  onChange={onChange(setDescriptionES)}
                  value={descriptionES}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="otherInformationES">
                  {t("BrandingPage.OtherInformation")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="otherInformationES"
                  onChange={onChange(setOtherInformationES)}
                  value={otherInformationES}
                />
              </div>
            </AccordionTab>
          </Accordion>
        ) : null}
        {selectedLanguages.ca ? (
          <Accordion className="p-col-12">
            <AccordionTab header="Català">
              <div className="p-col-12 p-md-2">
                <label htmlFor="titleCA">{t("BrandingPage.Title")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="titleCA"
                  onChange={onChange(setTitleCA)}
                  value={titleCA}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="descriptionCA">
                  {t("BrandingPage.Description")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="descriptionCA"
                  onChange={onChange(setDescriptionCA)}
                  value={descriptionCA}
                />
              </div>
              <div className="p-col-12 p-md-2">
                <label htmlFor="otherInformationCA">
                  {t("BrandingPage.OtherInformation")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="otherInformationCA"
                  onChange={onChange(setOtherInformationCA)}
                  value={otherInformationCA}
                />
              </div>
            </AccordionTab>
          </Accordion>
        ) : null}
        <Accordion className="p-col-12">
          <AccordionTab header="Meta Tags">
            {metaTagImage && metaTagImage.url ? (
              <div className="p-col-12">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${currentMetaTagImage}`}
                  width="80px"
                  height="auto"
                  alt=""
                />
              </div>
            ) : null}
            <div className="p-col-12 p-md-3">
              <FileUpload
                name="image"
                url={`${process.env.REACT_APP_BACKEND_URL}/setup/invalid`}
                accept="image/*"
                mode="basic"
                auto={true}
                onError={setImageFormData}
              />
              {cropMetaImage.src && (
                <ReactCrop
                  src={cropMetaImage.src}
                  crop={cropMetaImage.crop}
                  onImageLoaded={onMetaImageLoaded}
                  onComplete={onCropMetaImageComplete}
                  onChange={onCropMetaImageChange}
                />
              )}
            </div>
            <div className="p-col-12 p-md-2">
              <label htmlFor="metaTagTitle">{t("BrandingPage.Title")}</label>
            </div>
            <div className="p-col-12 p-md-4">
              <InputText
                id="metaTagTitle"
                onChange={onChange(setMetaTagTitle)}
                value={metaTagTitle}
              />
            </div>
            <div className="p-col-12 p-md-2">
              <label htmlFor="metaTagDescription">
                {t("BrandingPage.Description")}
              </label>
            </div>
            <div className="p-col-12 p-md-4">
              <InputText
                id="metaTagDescription"
                onChange={onChange(setMetaTagDescription)}
                value={metaTagDescription}
              />
            </div>
          </AccordionTab>
        </Accordion>
        <Accordion className="p-col-12">
          <AccordionTab header={t("BrandingPage.Currency")}>
            <div className="p-col-12 p-md-4">
              <Dropdown
                id="currency"
                optionValue="symbol"
                optionLabel="name"
                value={currency}
                options={currencies}
                onChange={(e) => {
                  setCurrency(e.value);
                }}
                placeholder={t("BrandingPage.SelectCurrency")}
              />
            </div>
          </AccordionTab>
        </Accordion>
        <Accordion className="p-col-12">
          <AccordionTab header="Social">
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="web">{t("BrandingPage.Web")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText id="web" onChange={onChange(setWeb)} value={web} />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="instagram">{t("BrandingPage.Instagram")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="instagram"
                  onChange={onChange(setInstagram)}
                  value={instagram}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="tripAdvisor">
                  {t("BrandingPage.TripAdvisor")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="tripAdvisor"
                  onChange={onChange(setTripAdvisor)}
                  value={tripAdvisor}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="facebook">{t("BrandingPage.Facebook")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="facebook"
                  onChange={onChange(setFacebook)}
                  value={facebook}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="whatsapp">{t("BrandingPage.Whatsapp")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="whatsapp"
                  onChange={onChange(setWhatsapp)}
                  value={whatsapp}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="whatsappOrder">
                  {t("BrandingPage.WhatsappOrder")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputSwitch
                  id="whatsappOrder"
                  onChange={onChange(setWhatsappOrder)}
                  checked={whatsappOrder}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="googleMapsLink">
                  {t("BrandingPage.GoogleMapsLink")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="googleMapsLink"
                  onChange={onChange(setGoogleMapsLink)}
                  value={googleMapsLink}
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <Accordion className="p-col-12">
          <AccordionTab header="Wifi">
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="wifiName">{t("BrandingPage.WifiName")}</label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="wifiName"
                  onChange={onChange(setWifiName)}
                  value={wifiName}
                />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-col-12 p-md-2">
                <label htmlFor="wifiPassword">
                  {t("BrandingPage.WifiPassword")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  id="wifiPassword"
                  onChange={onChange(setWifiPassword)}
                  value={wifiPassword}
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <Accordion className="p-col-12">
          <AccordionTab header={t("BrandingPage.ColorSchema")}>
            <div className={"p-col-12 " + (customSchema ? "hidden" : "")}>
              <div className="p-col-12 p-md-2">
                <label htmlFor="schemaColor">
                  {t("BrandingPage.SchemaColor")}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <Dropdown
                  id="schemaColor"
                  value={selectedSchema}
                  options={colorSchemas}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                    updateColorSchema(e.value);
                  }}
                  placeholder={t("BrandingPage.SelectColorSchema")}
                />
              </div>
              <div className="p-col-12 p-md-4">
                <Button
                  label={t("BrandingPage.PreviewColorSchema")}
                  icon="pi pi-eye"
                  onClick={() => {
                    setShowSchemaImage(true);
                  }}
                />
              </div>
            </div>
            {standard || premium ? (
              <>
                <div className={"p-col-12"}>
                  <div className="p-col-12 p-md-4">
                    <div className="p-col-12 p-md-4">
                      <label htmlFor="customSchema">
                        {t("BrandingPage.SetCustomSchemaColor")}
                      </label>
                    </div>
                    <InputSwitch
                      id="customSchema"
                      checked={customSchema}
                      onChange={(e) => {
                        setSelectedSchema(null);
                        setCustomSchema(e.value);
                        if (e.value) {
                          updateColors();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.HeaderSectionBackgroundColor")}</div>
                  <ColorPicker
                    value={headerSectionBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setHeaderSectionBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={headerSectionBackgroundColor}
                      onChange={onChange(setHeaderSectionBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.HeaderSectionTextColor")}</div>
                  <ColorPicker
                    value={headerSectionTextColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setHeaderSectionTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={headerSectionTextColor}
                      onChange={onChange(setHeaderSectionTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.MainMenuBackgroundColor")}</div>
                  <ColorPicker
                    value={mainMenuBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setMainMenuBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={mainMenuBackgroundColor}
                      onChange={onChange(setMainMenuBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.MainMenuTextColor")}</div>
                  <ColorPicker
                    style={{
                      width: "32px",
                    }}
                    value={mainMenuTextColor}
                    onChange={(e) => setMainMenuTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={mainMenuTextColor}
                      onChange={onChange(setMainMenuTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.CategoryMenuBackgroundColor")}</div>
                  <ColorPicker
                    value={categoryMenuBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setCategoryMenuBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={categoryMenuBackgroundColor}
                      onChange={onChange(setCategoryMenuBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.CategoryMenuTextColor")}</div>
                  <ColorPicker
                    value={categoryMenuTextColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setCategoryMenuTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={categoryMenuTextColor}
                      onChange={onChange(setCategoryMenuTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.ProductBackgroundColor")}</div>
                  <ColorPicker
                    value={productBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setProductBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={productBackgroundColor}
                      onChange={onChange(setProductBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>
                    {t("BrandingPage.BackgroundHighlightedProductColor")}
                  </div>
                  <ColorPicker
                    value={backgroundHighlightedProductColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) =>
                      setBackgroundHighlightedProductColor(e.value)
                    }
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={backgroundHighlightedProductColor}
                      onChange={onChange(setBackgroundHighlightedProductColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.LabelBoxBackgroundColor")}</div>
                  <ColorPicker
                    value={labelBoxBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setLabelBoxBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={labelBoxBackgroundColor}
                      onChange={onChange(setLabelBoxBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.LabelBoxTextColor")}</div>
                  <ColorPicker
                    value={labelBoxTextColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setLabelBoxTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={labelBoxTextColor}
                      onChange={onChange(setLabelBoxTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.NotAvailableBackgroundColor")}</div>
                  <ColorPicker
                    value={notAvailableBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setNotAvailableBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={notAvailableBackgroundColor}
                      onChange={onChange(setNotAvailableBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.NotAvailableTextColor")}</div>
                  <ColorPicker
                    value={notAvailableTextColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setNotAvailableTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={notAvailableTextColor}
                      onChange={onChange(setNotAvailableTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.TextTitleColor")}</div>
                  <ColorPicker
                    value={textTitleColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setTextTitleColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={textTitleColor}
                      onChange={onChange(setTextTitleColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.TextDescriptionColor")}</div>
                  <ColorPicker
                    value={textDescriptionColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setTextDescriptionColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={textDescriptionColor}
                      onChange={onChange(setTextDescriptionColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.PriceBackgroundColor")}</div>
                  <ColorPicker
                    value={priceBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setPriceBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={priceBackgroundColor}
                      onChange={onChange(setPriceBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.TextPriceColor")}</div>
                  <ColorPicker
                    value={textPriceColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setTextPriceColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={textPriceColor}
                      onChange={onChange(setTextPriceColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.PopupBackgroundColor")}</div>
                  <ColorPicker
                    value={popupBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setPopupBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={popupBackgroundColor}
                      onChange={onChange(setPopupBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.GeneralTextColor")}</div>
                  <ColorPicker
                    value={generalTextColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setGeneralTextColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={generalTextColor}
                      onChange={onChange(setGeneralTextColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.TopBackgroundColor")}</div>
                  <ColorPicker
                    value={topBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setTopBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={topBackgroundColor}
                      onChange={onChange(setTopBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.BottomBackgroundColor")}</div>
                  <ColorPicker
                    value={bottomBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setBottomBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={bottomBackgroundColor}
                      onChange={onChange(setBottomBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.HeaderBackgroundColor")}</div>
                  <ColorPicker
                    value={headerBackgroundColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setHeaderBackgroundColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={headerBackgroundColor}
                      onChange={onChange(setHeaderBackgroundColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.HeaderFontColor")}</div>
                  <ColorPicker
                    value={headerFontColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setHeaderFontColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={headerFontColor}
                      onChange={onChange(setHeaderFontColor)}
                    />
                  </div>
                </div>
                <div className={"p-col-12 " + (!customSchema ? "hidden" : "")}>
                  <div>{t("BrandingPage.BackgroundLightyColor")}</div>
                  <ColorPicker
                    value={backgroundLightyColor}
                    style={{
                      width: "32px",
                    }}
                    onChange={(e) => setBackgroundLightyColor(e.value)}
                  />
                  <div className="p-col-3">
                    {t("BrandingPage.Selected")}:{" "}
                    <InputText
                      value={backgroundLightyColor}
                      onChange={onChange(setBackgroundLightyColor)}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </AccordionTab>
        </Accordion>
        <Accordion className="p-col-12">
          <AccordionTab header={t("BrandingPage.YourLogo")}>
            <div className="p-col-12 p-md-2">
              <label htmlFor="input">{t("BrandingPage.Logo")}</label>
            </div>
            {logo && logo.url ? (
              <div className="p-col-12">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${currentImage}`}
                  width="80px"
                  height="auto"
                  alt=""
                />
              </div>
            ) : null}
            <div className="p-col-12 p-md-3">
              <FileUpload
                name="logo"
                url={`${process.env.REACT_APP_BACKEND_URL}/setup/invalid`}
                accept="image/*"
                mode="basic"
                auto={true}
                onError={setFormData}
              />
              {cropLogo.src && (
                <ReactCrop
                  src={cropLogo.src}
                  crop={cropLogo.crop}
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              )}
            </div>
          </AccordionTab>
        </Accordion>
        <div className="p-col-12">
          <div className="p-col-3">
            <Mutation
              mutation={
                brandingId ? BRANDING_UPDATE_MUTATION : BRANDING_CREATE_MUTATION
              }
              variables={{
                titleEN,
                titleES,
                titleCA,
                descriptionEN,
                descriptionES,
                descriptionCA,
                otherInformationEN,
                otherInformationES,
                otherInformationCA,
                web: fixUrl(web),
                facebook: fixUrl(facebook),
                instagram: fixUrl(instagram),
                tripAdvisor: fixUrl(tripAdvisor),
                googleMapsLink: fixUrl(googleMapsLink),
                whatsapp,
                whatsappOrder,
                wifiName,
                wifiPassword,
                brandingId,
                currency,
                metaTagTitle,
                metaTagDescription,
                customColor: customSchema,
                colorSchema: selectedSchema,
                headerSectionBackgroundColor,
                headerSectionTextColor,
                mainMenuBackgroundColor,
                mainMenuTextColor,
                categoryMenuBackgroundColor,
                categoryMenuTextColor,
                productBackgroundColor,
                backgroundHighlightedProductColor,
                labelBoxBackgroundColor,
                labelBoxTextColor,
                notAvailableBackgroundColor,
                notAvailableTextColor,
                textTitleColor,
                textDescriptionColor,
                priceBackgroundColor,
                textPriceColor,
                popupBackgroundColor,
                generalTextColor,
                topBackgroundColor,
                bottomBackgroundColor,
                headerBackgroundColor,
                headerFontColor,
                backgroundLightyColor,
              }}
              onCompleted={({ createBranding, updateBranding }: any) =>
                updated(createBranding || updateBranding)
              }
            >
              {(mutation: any) => (
                <Button
                  label={t("Save")}
                  icon="pi pi-pencil"
                  onClick={() => {
                    mutation();
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
