import gql from "graphql-tag";

export const BRANDING_CREATE_MUTATION = gql`
  mutation CreateBranding(
    $titleEN: String
    $titleES: String
    $titleCA: String
    $descriptionEN: String
    $descriptionES: String
    $descriptionCA: String
    $otherInformationEN: String
    $otherInformationES: String
    $otherInformationCA: String
    $web: String
    $facebook: String
    $instagram: String
    $tripAdvisor: String
    $wifiName: String
    $wifiPassword: String
    $currency: String
    $googleMapsLink: String
    $googleMapsIframe: String
    $whatsapp: String
    $whatsappOrder: Boolean
    $metaTagTitle: String
    $metaTagDescription: String
    $colorSchema: ID
    $customColor: Boolean
    $headerSectionBackgroundColor: String
    $headerSectionTextColor: String
    $mainMenuBackgroundColor: String
    $mainMenuTextColor: String
    $categoryMenuBackgroundColor: String
    $categoryMenuTextColor: String
    $productBackgroundColor: String
    $backgroundHighlightedProductColor: String
    $labelBoxBackgroundColor: String
    $labelBoxTextColor: String
    $notAvailableBackgroundColor: String
    $notAvailableTextColor: String
    $textTitleColor: String
    $textDescriptionColor: String
    $priceBackgroundColor: String
    $textPriceColor: String
    $popupBackgroundColor: String
    $generalTextColor: String
    $topBackgroundColor: String
    $bottomBackgroundColor: String
    $headerBackgroundColor: String
    $headerFontColor: String
    $backgroundLightyColor: String
  ) {
    createBranding(
      input: {
        data: {
          title_en: $titleEN
          title_es: $titleES
          title_ca: $titleCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          otherInformation_en: $otherInformationEN
          otherInformation_es: $otherInformationES
          otherInformation_ca: $otherInformationCA
          currency: $currency
          web: $web
          facebook: $facebook
          instagram: $instagram
          tripAdvisor: $tripAdvisor
          whatsapp: $whatsapp
          whatsappOrder: $whatsappOrder
          wifiName: $wifiName
          wifiPassword: $wifiPassword
          googleMapsLink: $googleMapsLink
          googleMapsIframe: $googleMapsIframe
          metaTagTitle: $metaTagTitle
          metaTagDescription: $metaTagDescription
          colorSchema: $colorSchema
          customColor: $customColor
          headerSectionBackgroundColor: $headerSectionBackgroundColor
          headerSectionTextColor: $headerSectionTextColor
          mainMenuBackgroundColor: $mainMenuBackgroundColor
          mainMenuTextColor: $mainMenuTextColor
          categoryMenuBackgroundColor: $categoryMenuBackgroundColor
          categoryMenuTextColor: $categoryMenuTextColor
          productBackgroundColor: $productBackgroundColor
          backgroundHighlightedProductColor: $backgroundHighlightedProductColor
          labelBoxBackgroundColor: $labelBoxBackgroundColor
          labelBoxTextColor: $labelBoxTextColor
          notAvailableBackgroundColor: $notAvailableBackgroundColor
          notAvailableTextColor: $notAvailableTextColor
          textTitleColor: $textTitleColor
          textDescriptionColor: $textDescriptionColor
          priceBackgroundColor: $priceBackgroundColor
          textPriceColor: $textPriceColor
          popupBackgroundColor: $popupBackgroundColor
          generalTextColor: $generalTextColor
          topBackgroundColor: $topBackgroundColor
          bottomBackgroundColor: $bottomBackgroundColor
          headerBackgroundColor: $headerBackgroundColor
          headerFontColor: $headerFontColor
          backgroundLightyColor: $backgroundLightyColor
        }
      }
    ) {
      branding {
        id
      }
    }
  }
`;

export const BRANDING_UPDATE_MUTATION = gql`
  mutation UpdateBranding(
    $brandingId: ID!
    $titleEN: String
    $titleES: String
    $titleCA: String
    $descriptionEN: String
    $descriptionES: String
    $descriptionCA: String
    $otherInformationEN: String
    $otherInformationES: String
    $otherInformationCA: String
    $web: String
    $facebook: String
    $instagram: String
    $tripAdvisor: String
    $wifiName: String
    $wifiPassword: String
    $currency: String
    $googleMapsLink: String
    $googleMapsIframe: String
    $metaTagTitle: String
    $metaTagDescription: String
    $whatsapp: String
    $whatsappOrder: Boolean
    $colorSchema: ID
    $customColor: Boolean
    $headerSectionBackgroundColor: String
    $headerSectionTextColor: String
    $mainMenuBackgroundColor: String
    $mainMenuTextColor: String
    $categoryMenuBackgroundColor: String
    $categoryMenuTextColor: String
    $productBackgroundColor: String
    $backgroundHighlightedProductColor: String
    $labelBoxBackgroundColor: String
    $labelBoxTextColor: String
    $notAvailableBackgroundColor: String
    $notAvailableTextColor: String
    $textTitleColor: String
    $textDescriptionColor: String
    $priceBackgroundColor: String
    $textPriceColor: String
    $popupBackgroundColor: String
    $generalTextColor: String
    $topBackgroundColor: String
    $bottomBackgroundColor: String
    $headerBackgroundColor: String
    $headerFontColor: String
    $backgroundLightyColor: String
  ) {
    updateBranding(
      input: {
        where: { id: $brandingId }
        data: {
          title_en: $titleEN
          title_es: $titleES
          title_ca: $titleCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          otherInformation_en: $otherInformationEN
          otherInformation_es: $otherInformationES
          otherInformation_ca: $otherInformationCA
          currency: $currency
          web: $web
          facebook: $facebook
          instagram: $instagram
          tripAdvisor: $tripAdvisor
          wifiName: $wifiName
          wifiPassword: $wifiPassword
          whatsapp: $whatsapp
          whatsappOrder: $whatsappOrder
          googleMapsLink: $googleMapsLink
          googleMapsIframe: $googleMapsIframe
          metaTagTitle: $metaTagTitle
          metaTagDescription: $metaTagDescription
          colorSchema: $colorSchema
          customColor: $customColor
          headerSectionBackgroundColor: $headerSectionBackgroundColor
          headerSectionTextColor: $headerSectionTextColor
          mainMenuBackgroundColor: $mainMenuBackgroundColor
          mainMenuTextColor: $mainMenuTextColor
          categoryMenuBackgroundColor: $categoryMenuBackgroundColor
          categoryMenuTextColor: $categoryMenuTextColor
          productBackgroundColor: $productBackgroundColor
          backgroundHighlightedProductColor: $backgroundHighlightedProductColor
          labelBoxBackgroundColor: $labelBoxBackgroundColor
          labelBoxTextColor: $labelBoxTextColor
          notAvailableBackgroundColor: $notAvailableBackgroundColor
          notAvailableTextColor: $notAvailableTextColor
          textTitleColor: $textTitleColor
          textDescriptionColor: $textDescriptionColor
          priceBackgroundColor: $priceBackgroundColor
          textPriceColor: $textPriceColor
          popupBackgroundColor: $popupBackgroundColor
          generalTextColor: $generalTextColor
          topBackgroundColor: $topBackgroundColor
          bottomBackgroundColor: $bottomBackgroundColor
          headerBackgroundColor: $headerBackgroundColor
          headerFontColor: $headerFontColor
          backgroundLightyColor: $backgroundLightyColor
        }
      }
    ) {
      branding {
        id
      }
    }
  }
`;
