import gql from "graphql-tag";

const MY_ACCOUNT_QUERY = gql`
  query MyAccount {
    user(id: "me") {
      id
      email
      klivrRole
      premium
      standard
      emailNews
      emailUpdates
    }
  }
`;

export default MY_ACCOUNT_QUERY;
