export interface FieldValidator {
  field: string;
  message: string;
}

export const validateForm = (fields: FieldValidator[]): string[] => {
  return fields
    .filter(({ field }: FieldValidator) => !field.length)
    .map(({ message }: FieldValidator) => message);
};
