import { GQLColorSchema } from "entities/colorSchema";

export const AUTH_TOKEN = "klivr-bar-admin-token";
export const AUTH_CLIENT = "klivr-bar-admin-client";

export const SUPPORTED_LANGUAGES = [
  { label: "English", value: "en" },
  { label: "Español", value: "es" },
  { label: "Català", value: "ca" },
];

export const DATE_FORMAT = "DD-MM-YYYY";
export const GQL_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";

export const ORIGINAL_COLOR_SCHEMA: GQLColorSchema = {
  headerBackgroundColor: "ff9200",
  headerSectionBackgroundColor: "",
  headerSectionTextColor: "",
  headerFontColor: "090657",
  categoryMenuBackgroundColor: "",
  categoryMenuTextColor: "",
  productBackgroundColor: "",
  labelBoxBackgroundColor: "",
  labelBoxTextColor: "",
  mainMenuBackgroundColor: "",
  mainMenuTextColor: "",
  notAvailableBackgroundColor: "",
  notAvailableTextColor: "",
  priceBackgroundColor: "",
  popupBackgroundColor: "",
  backgroundLightyColor: "",
  topBackgroundColor: "ff9200",
  bottomBackgroundColor: "ff9200",
  generalTextColor: "090657",
  iconColor: "090657",
  textTitleColor: "090657",
  textDescriptionColor: "090657",
  textPriceColor: "090657",
  backgroundHighlightedProductColor: "ff9200",
  textHighlightedProductColor: "090657",
};
