import gql from "graphql-tag";

export const CLIENT_QUERY = gql`
  query Client {
    client(id: "1") {
      id
      name
      uniqueName
      public
      uid
    }
  }
`;
