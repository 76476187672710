import gql from "graphql-tag";

export const SETTINGS_CREATE_MUTATION = gql`
  mutation CreateSettings(
    $en: Boolean
    $es: Boolean
    $ca: Boolean
    $defaultLanguage: ENUM_SETTINGS_DEFAULTLANGUAGE
    $uniqueName: String
    $publicDomain: Boolean
  ) {
    createSetting(
      input: {
        data: { en: $en, es: $es, ca: $ca, defaultLanguage: $defaultLanguage }
      }
    ) {
      setting {
        id
      }
    }

    updateClient(
      input: { data: { public: $publicDomain, uniqueName: $uniqueName } }
    ) {
      client {
        public
      }
    }
  }
`;

export const SETTINGS_UPDATE_MUTATION = gql`
  mutation UpdateSettings(
    $settingsId: ID!
    $en: Boolean
    $es: Boolean
    $ca: Boolean
    $defaultLanguage: ENUM_SETTINGS_DEFAULTLANGUAGE
    $uniqueName: String
    $publicDomain: Boolean
  ) {
    updateSetting(
      input: {
        where: { id: $settingsId }
        data: { en: $en, es: $es, ca: $ca, defaultLanguage: $defaultLanguage }
      }
    ) {
      setting {
        id
      }
    }

    updateClient(
      input: { data: { public: $publicDomain, uniqueName: $uniqueName } }
    ) {
      client {
        id
      }
    }
  }
`;
