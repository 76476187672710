import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLGroup } from "entities/group";
import { GQLCategory } from "entities/category";

import { CATEGORIES_WITH_GROUPS_QUERY } from "graphql/queries/categories/categoriesWithGroups";

import { Categories } from "components/Categories";

const CategoriesContainer = () => {
  return (
    <Query query={CATEGORIES_WITH_GROUPS_QUERY}>
      {({
        data: { categories, groups },
        refetch,
      }: QueryResponse<GQLGroup> & QueryResponse<GQLCategory>) => {
        return (
          <Categories data={categories} allGroups={groups} refetch={refetch} />
        );
      }}
    </Query>
  );
};

export default CategoriesContainer;
