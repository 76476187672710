import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLNotificationPopup } from "entities/notificationPopup";

import { NOTIFICATIONS_QUERY } from "graphql/queries/notifications/notifications";
import { Notifications } from "components/Notifications";

const NotificationsContainer = () => {
  return (
    <div>
      <Query query={NOTIFICATIONS_QUERY}>
        {({
          data: { notifications },
          refetch,
        }: QueryResponse<GQLNotificationPopup>) => {
          return <Notifications data={notifications} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default NotificationsContainer;
