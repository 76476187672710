import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Mutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { LOGIN_MUTATION } from "graphql/mutations/login";
import { Auth } from "utils/auth";
import { GQLAuthResponse, GQLLogin } from "entities/auth";

import "./Login.scss";
import { validateForm, FieldValidator } from "utils/validators";
import Api from "api";

interface PageLoginProps {}

export const PageLogin: React.FC<PageLoginProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorClient, setErrorClient] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (Auth.isAuthenticated()) {
    window.location.href = "/";
  }

  const queryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (queryString.c) {
      Api.confirmAccount(queryString.c as string)
        .then((res: any) => {
          if (!res.error) {
            setMessage(t("LoginPage.ConfirmAccount.Success"));
            setErrorMessage("");
          } else {
            setMessage("");
            setErrorMessage(t("LoginPage.ConfirmAccount.InvalidCode"));
          }
        })
        .catch(() => {
          setMessage("");
          setErrorMessage(t("LoginPage.ConfirmAccount.Error"));
        });
    }
  });
  const loggedIn = (login?: GQLLogin) => {
    if (login && login.jwt) {
      setErrorClient(false);
      setError(false);
      Auth.authenticate(login.jwt);
      window.location.href = "/";
    } else {
      Auth.clear();
      setError(true);
    }
  };

  const onKeyInputDown = (mutation: any) => (e: any) => {
    if (e.key === "Enter" && email.length && password.length) {
      mutation();
    }
  };

  const validators: FieldValidator[] = [
    {
      field: email,
      message: t("LoginPage.ErrorMsgs.EnterEmail"),
    },
    {
      field: password,
      message: t("LoginPage.ErrorMsgs.EnterPassword"),
    },
  ];

  return (
    <div className="p-grid btn-login-container">
      <Mutation
        mutation={LOGIN_MUTATION}
        variables={{ email: email.toLocaleLowerCase(), password }}
        onCompleted={({ login }: GQLAuthResponse) => loggedIn(login)}
        onError={() => setError(true)}
      >
        {(mutation: any) => (
          <div className="wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo_black.svg`}
              alt="Klivr Club"
            />
            {errorMessage ? (
              <div className="error-msg">{errorMessage}</div>
            ) : null}
            {message ? <div>{message}</div> : null}
            <span className="p-float-label">
              <InputText
                id="in"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={onKeyInputDown(mutation)}
              />
              <label htmlFor="in">{t("Email")}</label>
            </span>
            <span className="p-float-label">
              <Password
                id="in"
                value={password}
                feedback={false}
                onKeyDown={onKeyInputDown(mutation)}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <label htmlFor="in">{t("Password")}</label>
            </span>

            {error ? (
              <div className="error-msg">{t("LoginPage.ErrorMsgs.Error")}</div>
            ) : null}
            {errorClient ? (
              <div className="error-msg">
                {t("LoginPage.ErrorMsgs.InvalidUser")}
              </div>
            ) : null}
            <div className="button-container">
              <Button
                label={t("LoginPage.Login")}
                className="p-button-raised"
                disabled={!!validateForm(validators).length}
                onClick={() => mutation({ email, password })}
              />
            </div>
          </div>
        )}
      </Mutation>
    </div>
  );
};
