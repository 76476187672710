import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";

import { MENU_QUERY } from "graphql/queries/menu/menu";

import { GQLProduct } from "entities/product";
import Menu from "components/Menu";

const MenuContainer = () => {
  return (
    <Query query={MENU_QUERY}>
      {({ data: { products }, refetch }: QueryResponse<GQLProduct>) => {
        return <Menu data={products} refetch={refetch} />;
      }}
    </Query>
  );
};

export default MenuContainer;
