import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLBranding } from "entities/branding";

import { BRANDING_QUERY } from "graphql/queries/branding/branding";

import { Branding } from "components/Branding";
import { GQLColorSchema } from "entities/colorSchema";
import { GQLCurrency } from "entities/currency";
import { GQLClient } from "entities/client";

const BrandingContainer = () => {
  return (
    <Query query={BRANDING_QUERY}>
      {({
        data: { brandings, colorSchemas, currencies, clients },
        refetch,
      }: QueryResponse<GQLBranding> &
        QueryResponse<GQLColorSchema> &
        QueryResponse<GQLClient> &
        QueryResponse<GQLCurrency>) => {
        return (
          <Branding
            data={(brandings || [{} as GQLBranding])[0]}
            client={clients[0]}
            colorSchemas={colorSchemas}
            currencies={currencies}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

export default BrandingContainer;
