import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Trans } from "react-i18next";

import { IMenuOption } from "config/menu";
import { useRecoilValue } from "recoil";
import { userState } from "atoms/user";
import { clientState } from "atoms/client";

interface AppSubmenuProps {
  className?: string;
  items: IMenuOption[];
  onMenuItemClick: any;
  root?: boolean;
}

interface AppSubmenuState {
  activeIndex?: number | null;
}

const AppSubmenu = ({
  items,
  onMenuItemClick,
  className,
  root,
}: AppSubmenuProps) => {
  const [state, setState] = useState<AppSubmenuState>({
    activeIndex: null,
  });

  const user = useRecoilValue(userState);
  const client = useRecoilValue(clientState);

  const onSubmenuItemClick = (event: any, item: any, index: number) => {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === state.activeIndex) setState({ activeIndex: null });
    else setState({ activeIndex: index });

    if (onMenuItemClick) {
      onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const renderLinkContent = (item: IMenuOption) => {
    let submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    let badge = item.badge && (
      <span className="menuitem-badge">{item.badge}</span>
    );

    return (
      <>
        <i className={item.icon}></i>
        <span>
          <Trans>Menu.{item.label}</Trans>
        </span>
        {submenuIcon}
        {badge}
      </>
    );
  };

  const renderLink = (item: any, i: any) => {
    let content = renderLinkContent(item);

    const showMenu =
      (!item.roles ||
        (item.roles.length > 0 && item.roles.includes(user.role))) &&
      (!item.visible || item.visible(user, client));

    if (item.to) {
      return showMenu ? (
        <NavLink
          activeClassName="active-route"
          className={item.className || ""}
          to={item.to}
          onClick={(e: any) => onSubmenuItemClick(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      ) : null;
    } else {
      return showMenu ? (
        <a
          href={item.url}
          onClick={(e) => onSubmenuItemClick(e, item, i)}
          target={item.target}
        >
          {content}
        </a>
      ) : null;
    }
  };

  const itemsToRender =
    items &&
    items.map((item, i) => {
      let active = state.activeIndex === i;
      let styleClass = classNames(item.badgeStyleClass, {
        "active-menuitem": active && !item.to,
      });

      return (
        <li className={styleClass} key={i}>
          {item.items && root === true && <div className="arrow"></div>}
          {renderLink(item, i)}
          <AppSubmenu items={item.items} onMenuItemClick={onMenuItemClick} />
        </li>
      );
    });

  return itemsToRender ? <ul className={className}>{itemsToRender}</ul> : null;
};

export default AppSubmenu;
