import React from "react";

import DashboardContainer from "containers/Dashboard";
import { useRecoilValue } from "recoil";
import { clientState } from "atoms/client";

export const PageDashboard: React.FC = () => {
  const client = useRecoilValue(clientState);

  if (!client) {
    window.location.href = "/stores";
  }

  return (
    <div className="p-grid p-fluid dashboard">
      <DashboardContainer />
    </div>
  );
};
