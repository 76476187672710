import React from "react";
import { useTranslation } from "react-i18next";

import SettingsContainer from "containers/Settings";

export const PageSettings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("SettingsPage.PageTitle")}</h1>
            <SettingsContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
