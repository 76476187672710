import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { Auth } from "./auth";

const authLink = setContext((_, { headers }) => {
  const token = Auth.getToken();
  const client = Auth.getClient();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      store: client ? `Bearer ${client}` : "",
    },
  };
});

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});
const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default client;
