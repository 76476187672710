import gql from "graphql-tag";

export const CATEGORY_CREATE_MUTATION = gql`
  mutation CreateCategory(
    $nameEN: String!
    $nameES: String!
    $nameCA: String!
    $descriptionEN: String!
    $descriptionES: String!
    $descriptionCA: String!
    $groups: [ID]
    $order: Int
  ) {
    createCategory(
      input: {
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          groups: $groups
          order: $order
        }
      }
    ) {
      category {
        id
      }
    }
  }
`;

export const CATEGORY_UPDATE_MUTATION = gql`
  mutation UpdateCategory(
    $categoryId: ID!
    $nameEN: String!
    $nameES: String!
    $nameCA: String!
    $descriptionEN: String!
    $descriptionES: String!
    $descriptionCA: String!
    $groups: [ID]
    $order: Int
  ) {
    updateCategory(
      input: {
        where: { id: $categoryId }
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          description_en: $descriptionEN
          description_es: $descriptionES
          description_ca: $descriptionCA
          groups: $groups
          order: $order
        }
      }
    ) {
      category {
        id
      }
    }
  }
`;

export const CATEGORY_DELETE_MUTATION = gql`
  mutation DeleteCategory($categoryId: ID!) {
    deleteCategory(input: { where: { id: $categoryId } }) {
      category {
        id
      }
    }
  }
`;
