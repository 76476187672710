import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import { GQLUser } from "entities/user";
import { Password } from "primereact/password";
import {
  MY_ACCOUNT_PASSWORD_UPDATE_MUTATION,
  MY_ACCOUNT_UPDATE_MUTATION,
} from "graphql/mutations/myAccount";
import { useTranslation } from "react-i18next";

interface MyAccountProps {
  data: GQLUser;
  refetch: any;
}

export const MyAccount: React.FC<MyAccountProps> = ({ data, refetch }) => {
  const {
    id: originalId,
    email: originalEmail,
    emailNews: originalEmailNews,
    emailUpdates: originalEmailUpdates,
  } = data;
  const { t } = useTranslation();

  const [userId, setUserId] = useState(originalId || null);
  const [email, setEmail] = useState(originalEmail || "");
  const [emailNews, setEmailNews] = useState(originalEmailNews && true);
  const [emailUpdates, setEmailUpdates] = useState(
    originalEmailUpdates && true
  );
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const updated = (response: any) => {
    if (response && response.user && response.user.id) {
      setUserId(response.user.id);
      setMessage(t("MyAccountPage.Msgs.UserUpdated"));

      refetch();
      setError(false);
    } else {
      setError(true);
      setMessage(t("MyAccountPage.Msgs.ErrorUpdating"));
    }
  };
  const onChange = (setFunction: any) => (e: any) => {
    setFunction(e.value || e.target.value);
  };

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">{t("MyAccountPage.AccountDetails")}</h3>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="email">{t("MyAccountPage.Email")}</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText id="email" onChange={onChange(setEmail)} value={email} />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="password">{t("MyAccountPage.Password")}</label>
          </div>
          <div className="p-col-12 p-md-4">
            <Password
              id="password"
              onChange={onChange(setPassword)}
              value={password}
            />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-4">
            <label htmlFor="emailNews">{t("MyAccountPage.EmailNews")}</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputSwitch
              id="emailNews"
              onChange={onChange(setEmailNews)}
              checked={emailNews}
            />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-4">
            <label htmlFor="emailUpdated">
              {t("MyAccountPage.EmailUpdates")}
            </label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputSwitch
              id="emailUpdates"
              onChange={onChange(setEmailUpdates)}
              checked={emailUpdates}
            />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-3">
            <Mutation
              mutation={
                password.length
                  ? MY_ACCOUNT_PASSWORD_UPDATE_MUTATION
                  : MY_ACCOUNT_UPDATE_MUTATION
              }
              variables={{
                email,
                userId,
                password,
                emailUpdates,
                emailNews,
              }}
              onCompleted={({ updateUser }: any) => updated(updateUser)}
            >
              {(mutation: any) => (
                <Button
                  label={t("Save")}
                  icon="pi pi-pencil"
                  onClick={() => {
                    mutation();
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
