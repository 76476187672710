import { atom } from "recoil";

export const userState = atom({
  key: "user",
  default: {
    email: "",
    role: "",
    standard: false,
    premium: false,
    loaded: false,
  },
});
