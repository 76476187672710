import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLCategory } from "entities/category";
import { GQLProduct } from "entities/product";

import { PRODUCTS_WITH_EXTRA_DATA_QUERY } from "graphql/queries/products/productsWithExtraData";
import { Products } from "components/Products";
import { GQLProductAttribute } from "entities/productAttribute";

const ProductsContainer = () => {
  return (
    <div>
      <Query query={PRODUCTS_WITH_EXTRA_DATA_QUERY}>
        {({
          data: { categories, products, productAttributes },
          refetch,
        }: QueryResponse<GQLCategory> &
          QueryResponse<GQLProduct> &
          QueryResponse<GQLProductAttribute>) => {
          return (
            <Products
              data={products}
              categories={categories}
              productAttributes={productAttributes}
              refetch={refetch}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default ProductsContainer;
