import React, { Component } from "react";
import { Route } from "react-router-dom";
import classNames from "classnames";
import CookieConsent from "react-cookie-consent";
import Tour from "reactour";

import { Growl } from "primereact/growl";

import { MAIN_MENU_OPTIONS } from "config/menu";
import { MAIN_MENU_STORE_OPTIONS } from "config/menu-store";

import { PageDashboard } from "pages/Dashboard";
import { PageMenu } from "pages/Menu";
import { PageClients } from "pages/Clients";
import { PageGroup } from "pages/Group";
import { PageProducts } from "pages/Products";
import { PageLogin } from "pages/Login";
import { PageBranding } from "pages/Branding";
import { PageSettings } from "pages/Settings";
import { PageNotifications } from "pages/Notifications";
import { PageCategories } from "pages/Categories";
import { PageQRCode } from "pages/QRCode";
import { PageMyAccount } from "pages/MyAccount";

import Api from "api";

import { PrivateRoute } from "components/PrivateRoute";

import { AppTopBar } from "./AppTopBar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import getTourSteps from "config/tours";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { Auth } from "utils/auth";

import "./layout/layout.scss";
import "./App.scss";
import { withTranslation } from "react-i18next";
import { ClientSelector } from "components/ClientSelector";

interface AppState {
  layoutMode: string;
  staticMenuInactive: boolean;
  mobileMenuActive: boolean;
  isAuthenticated: boolean;
  isTourOpen: boolean;
}

class App extends Component<any, AppState> {
  private menuClick: boolean;
  private growl;

  constructor(props: any) {
    super(props);

    this.menuClick = false;

    this.state = {
      layoutMode: "static",
      staticMenuInactive: false,
      mobileMenuActive: false,
      isAuthenticated: Auth.isAuthenticated(),
      isTourOpen: false,
    };
  }

  onWrapperClick = () => {
    if (!this.menuClick) {
      this.setState({
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  };

  onToggleMenu = (event: any) => {
    this.menuClick = true;

    if (this.isDesktop()) {
      this.setState({
        staticMenuInactive: !this.state.staticMenuInactive,
      });
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  };

  onSidebarClick = () => {
    this.menuClick = true;
  };

  onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      this.setState({
        mobileMenuActive: false,
      });
    }
  };

  addClass(element: any, className: string) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += " " + className;
    }
  }

  removeClass(element: any, className: string) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive) {
      this.addClass(document.body, "body-overflow-hidden");
    } else {
      this.removeClass(document.body, "body-overflow-hidden");
    }
  }

  closeTour = () => {
    Api.setTourRead();
    this.setState({
      ...this.state,
      isTourOpen: false,
    });
  };

  openTour = () => {
    this.setState({
      ...this.state,
      isTourOpen: true,
    });
  };

  render() {
    const logo = `${process.env.PUBLIC_URL}/images/logo.svg`;
    const { isAuthenticated } = this.state;
    const { t } = this.props;

    const wrapperClass = classNames("layout-wrapper", "layout-static", {
      "layout-static-sidebar-inactive": this.state.staticMenuInactive,
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
      "layout-full-width": !isAuthenticated,
    });

    const sidebarClassName = classNames(
      "layout-sidebar",
      "layout-sidebar-dark"
    );

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <CookieConsent
          location="bottom"
          buttonText={t("CookieConsent.ButtonAccept")}
          cookieName="clientApp"
          containerClasses="cookie-consent-container"
          buttonClasses="cookie-consent-button"
          expires={150}
        >
          {t("CookieConsent.Description")}{" "}
          {t("CookieConsent.TermsAndConditions")}
          <a href="klivr.club/terms.html" style={{ fontSize: "10px" }}>
            {t("CookieConsent.Here")}
          </a>
        </CookieConsent>
        {isAuthenticated ? (
          <>
            <AppTopBar
              onToggleMenu={this.onToggleMenu}
              openTour={this.openTour}
            />
            <Growl ref={(el) => (this.growl = el)} />

            <Tour
              steps={getTourSteps(t)}
              isOpen={this.state.isTourOpen}
              onRequestClose={this.closeTour}
              className="tour"
            />
            <div className={sidebarClassName} onClick={this.onSidebarClick}>
              <div className="layout-logo">
                <img alt="Logo" src={logo} />
              </div>
              <AppMenu
                model={MAIN_MENU_OPTIONS}
                onMenuItemClick={this.onMenuItemClick}
              />
              <ClientSelector />
              <AppMenu
                model={MAIN_MENU_STORE_OPTIONS}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </>
        ) : null}

        <div className="layout-main">
          <Route path="/login" component={PageLogin} />
          <PrivateRoute path="/" exact component={PageDashboard} />
          <PrivateRoute path="/menu" exact component={PageMenu} />
          <PrivateRoute path="/groups" exact component={PageGroup} />
          <PrivateRoute path="/stores" exact component={PageClients} />
          <PrivateRoute path="/categories" exact component={PageCategories} />
          <PrivateRoute path="/products" exact component={PageProducts} />
          <PrivateRoute
            path="/notifications"
            exact
            component={PageNotifications}
          />
          <PrivateRoute path="/qrcode" exact component={PageQRCode} />
          <PrivateRoute path="/setup/branding" exact component={PageBranding} />
          <PrivateRoute path="/setup/settings" exact component={PageSettings} />
          <PrivateRoute path="/my-account" exact component={PageMyAccount} />
        </div>

        <AppFooter />

        <div className="layout-mask"></div>
      </div>
    );
  }
}

export default withTranslation()(App);
