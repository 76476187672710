import React from "react";
import { useTranslation } from "react-i18next";

import BrandingContainer from "containers/Branding";

export const PageBranding: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("BrandingPage.PageTitle")}</h1>
            <BrandingContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
