import React from "react";
import { useTranslation } from "react-i18next";

import MenuContainer from "containers/Menu";

export const PageMenu: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("MenuPage.PageTitle")}</h1>
            <MenuContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
