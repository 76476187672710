import gql from "graphql-tag";

export const PRODUCTS_WITH_EXTRA_DATA_QUERY = gql`
  query DataSectionsWithExtraData {
    categories {
      id
      name_en
      name_es
      name_ca
    }

    products {
      id
      name_en
      name_es
      name_ca
      description_en
      description_es
      description_ca
      price
      image {
        url
      }
      available
      highlight
      new
      mainPage
      hidden
      order
      multiPrice
      product_prices {
        id
        label
        price
      }
      categories {
        id
        name_en
        name_es
        name_ca
      }
    }

    productAttributes {
      id
      name_en
      name_es
      name_ca
    }
  }
`;
