import gql from "graphql-tag";

export const GROUP_CREATE_MUTATION = gql`
  mutation CreateGroup(
    $nameEN: String
    $nameES: String
    $nameCA: String
    $order: Int
    $days: String
    $from: Time
    $to: Time
    $alwaysEnabled: Boolean
    $disabled: Boolean
  ) {
    createGroup(
      input: {
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          order: $order
          days: $days
          to: $to
          from: $from
          alwaysEnabled: $alwaysEnabled
          disabled: $disabled
        }
      }
    ) {
      group {
        id
      }
    }
  }
`;

export const GROUP_UPDATE_MUTATION = gql`
  mutation UpdateGroup(
    $groupId: ID!
    $nameEN: String
    $nameES: String
    $nameCA: String
    $order: Int
    $days: String
    $from: Time
    $to: Time
    $alwaysEnabled: Boolean
    $disabled: Boolean
  ) {
    updateGroup(
      input: {
        where: { id: $groupId }
        data: {
          name_en: $nameEN
          name_es: $nameES
          name_ca: $nameCA
          order: $order
          days: $days
          to: $to
          from: $from
          alwaysEnabled: $alwaysEnabled
          disabled: $disabled
        }
      }
    ) {
      group {
        id
      }
    }
  }
`;

export const GROUP_DELETE_MUTATION = gql`
  mutation DeleteGroup($groupId: ID!) {
    deleteGroup(input: { where: { id: $groupId } }) {
      group {
        id
      }
    }
  }
`;
