import gql from "graphql-tag";

export const CATEGORIES_WITH_GROUPS_QUERY = gql`
  query CategoriesAndGroups {
    categories {
      id
      name_en
      name_es
      name_ca
      description_en
      description_es
      description_ca
      groups {
        id
        name_es
        name_en
        name_ca
      }
      order
    }

    groups {
      id
      name_es
      name_en
      name_ca
    }
  }
`;
