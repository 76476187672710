import { AUTH_TOKEN, AUTH_CLIENT } from "config/constants";

export const Auth = {
  isAuthenticated: () => {
    const token = localStorage.getItem(AUTH_TOKEN) || "";
    return !!token.length;
  },
  getToken: () => {
    return localStorage.getItem(AUTH_TOKEN) || "";
  },
  getClient: () => {
    return localStorage.getItem(AUTH_CLIENT) || "";
  },
  setClient: (client: string) => {
    localStorage.setItem(AUTH_CLIENT, client);
  },
  removeClient: () => {
    localStorage.removeItem(AUTH_CLIENT);
  },
  authenticate: (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
  },
  clear: () => {
    localStorage.removeItem(AUTH_TOKEN);
  },
};
