import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLSettings } from "entities/settings";

import { SETTINGS_QUERY } from "graphql/queries/settings/settings";

import { Settings } from "components/Settings";
import { GQLClient } from "entities/client";

const SettingsContainer = () => {
  return (
    <div>
      <Query query={SETTINGS_QUERY}>
        {({
          data: { settings, clients },
          refetch,
        }: QueryResponse<GQLSettings> & QueryResponse<GQLClient>) => {
          return (
            <Settings
              data={(settings || [{} as GQLSettings])[0]}
              client={clients[0]}
              refetch={refetch}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default SettingsContainer;
