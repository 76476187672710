import gql from "graphql-tag";

export const CLIENTS_QUERY = gql`
  query Clients {
    clients {
      id
      name
      uniqueName
      public
      uid
    }
  }
`;
