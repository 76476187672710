import React from "react";

import Query from "components/Query";

import { DASHBOARD_QUERY } from "graphql/queries/dashboard";
import { GQLDashboard } from "entities/dashboard";

import { Dashboard } from "components/Dashboard";
import { QueryResponseCustom } from "entities/query";

const DashboardContainer = () => {
  return (
    <Query query={DASHBOARD_QUERY}>
      {({ data, refetch }: QueryResponseCustom<GQLDashboard>) => {
        return <Dashboard data={data} refetch={refetch} />;
      }}
    </Query>
  );
};

export default DashboardContainer;
