import React, { useState, useEffect } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import {
  CLIENT_CREATE_MUTATION,
  CLIENT_UPDATE_MUTATION,
  CLIENT_DELETE_MUTATION,
} from "graphql/mutations/clients";

import { GQLClient } from "entities/client";
import { validateForm, FieldValidator } from "utils/validators";
import { InputSwitch } from "primereact/inputswitch";
import Api from "api";

interface ClientsProps {
  data: GQLClient[];
  refetch: any;
}

export const Clients: React.FC<ClientsProps> = ({ data, refetch }) => {
  const { t } = useTranslation();
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [uniqueName, setUniqueName] = useState("");
  const [publicProfile, setPublicProfile] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [dataTableSelection, setDataTableSelection] = useState(null);

  const resetFields = () => {
    setName("");
    setUniqueName("");
    setPublicProfile(true);
    setEditId(null);
  };

  useEffect(() => {
    Api.getUser();
  });
  const upserted = async (response: any) => {
    if (response && response.client && response.client.id) {
      setError(false);
      if (editId) {
        setMessage(t("ClientsPage.Msgs.Updated"));
      } else {
        await Api.updateUser(response.client.id);
        // await Api.createBranding(response.client.id);
        // await Api.createSettings(response.client.id);
        setMessage(t("ClientsPage.Msgs.Created"));
      }
      resetFields();
      refetch();
    } else {
      setError(true);
      if (editId) {
        setMessage(t("ClientsPage.Msgs.ErrorUpdated"));
      } else {
        setMessage(t("ClientsPage.Msgs.ErrorCreated"));
      }
    }
    window.scrollTo(0, 0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.client && response.client.id) {
      setError(false);
      setMessage(t("ClientsPage.Msgs.Deleted"));
      refetch();
    } else {
      setError(true);
      setMessage(t("ClientsPage.Msgs.ErrorDeleting"));
    }
    window.scrollTo(0, 0);
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={CLIENT_DELETE_MUTATION}
        variables={{
          clientId: rowData.id,
        }}
        onCompleted={({ deleteClient }: any) => {
          deleted(deleteClient);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            disabled={data.length === 1}
            onClick={() => {
              const r = window.confirm(
                t("ClientsPage.Msgs.ConfirmDelete").replace(
                  "%NAME%",
                  rowData.name
                )
              );

              if (r === true) {
                mutation();
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setUniqueName(rowData.uniqueName);
          setPublicProfile(rowData.public);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const validators: FieldValidator[] = [
    {
      field: name,
      message: t("ClientsPage.Msgs.CompleteName"),
    },
    {
      field: String(uniqueName),
      message: t("ClientsPage.Msgs.CompleteUniqueName"),
    },
  ];

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? CLIENT_UPDATE_MUTATION : CLIENT_CREATE_MUTATION}
        variables={{
          name,
          uniqueName,
          adminUser: data[0].adminUser,
          public: publicProfile,
          clientId: editId,
        }}
        onCompleted={({ createClient, updateClient }: any) => {
          upserted(createClient || updateClient);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? t("ClientsPage.Update") : t("ClientsPage.Create")}
            icon="pi pi-pencil"
            disabled={!!validateForm(validators).length}
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const onChange = (setter: any) => (e) => {
    setter(e.value || e.target.value);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label={t("ClientsPage.Create")}
          icon="pi pi-external-link"
          onClick={() => {
            resetFields();
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? t("Edit") : t("Add")}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">{t("ClientsPage.Name")}:</div>
          <div className="p-col-12">
            <InputText
              placeholder={t("ClientsPage.Name")}
              value={name}
              onChange={onChange(setName)}
            />
          </div>
          <div className="p-col-12">{t("ClientsPage.UniqueName")}:</div>
          <div className="p-col-12">
            <InputText
              placeholder={t("ClientsPage.UniqueName")}
              value={uniqueName}
              onChange={onChange(setUniqueName)}
            />
          </div>

          <div className="p-col-12">{t("ClientsPage.PublicProfile")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={publicProfile}
              onChange={(e) => setPublicProfile(e.value)}
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <DataTable
          responsive={true}
          value={data}
          selectionMode="single"
          selection={dataTableSelection}
          onSelectionChange={(event: any) => setDataTableSelection(event.value)}
        >
          <Column field="name" header={t("ClientsPage.Name")} />
          <Column field="uniqueName" header={t("ClientsPage.UniqueName")} />
          <Column field="public" header={t("ClientsPage.Public")} />
          <Column
            field="id"
            header={t("Edit")}
            sortable={false}
            body={editColumn}
          />
          <Column
            field="id"
            header={t("Delete")}
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
