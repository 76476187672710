import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

import {
  GROUP_CREATE_MUTATION,
  GROUP_UPDATE_MUTATION,
  GROUP_DELETE_MUTATION,
} from "graphql/mutations/groups";

import { GQLGroup } from "entities/group";
import { validateForm, FieldValidator } from "utils/validators";
import { languagesState } from "atoms/languages";
import { defaultLanguageState } from "atoms/defaultLanguage";

interface GroupsProps {
  data: GQLGroup[];
  refetch: any;
}
const FORMAT_TIME = "HH:mm:ss.SSS";
const LIST_DAYS = [
  {
    id: "MO",
  },
  {
    id: "TU",
  },
  {
    id: "WE",
  },
  {
    id: "TH",
  },
  {
    id: "FR",
  },
  {
    id: "SA",
  },
  {
    id: "SO",
  },
  {
    id: "HO",
  },
];

export const Groups: React.FC<GroupsProps> = ({ data, refetch }) => {
  const { t } = useTranslation();
  const defaultLanguage = useRecoilValue(defaultLanguageState);
  const selectedLanguages = useRecoilValue(languagesState);
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [nameEN, setNameEN] = useState("");
  const [nameES, setNameES] = useState("");
  const [nameCA, setNameCA] = useState("");
  const [order, setOrder] = useState(0);
  const [days, setDays] = useState([]);
  const [isAlwaysEnabled, setAlwaysEnabled] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [timeFrom, setTimeFrom] = useState<any>(null);
  const [timeTo, setTimeTo] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [dataTableSelection, setDataTableSelection] = useState(null);

  const resetFields = () => {
    setNameEN("");
    setNameES("");
    setNameCA("");
    setEditId(null);
    setOrder(0);
    setDays([]);
    setAlwaysEnabled(true);
    setDisabled(false);
    setTimeFrom(null);
    setTimeTo(null);
  };
  const upserted = (response: any) => {
    if (response && response.group && response.group.id) {
      setError(false);
      if (editId) {
        setMessage(t("GroupsPage.Msgs.Updated"));
      } else {
        setMessage(t("GroupsPage.Msgs.Created"));
      }

      refetch();
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage(t("GroupsPage.Msgs.ErrorUpdated"));
      } else {
        setMessage(t("GroupsPage.Msgs.ErrorCreated"));
      }
    }
    resetFields();
    window.scrollTo(0, 0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.group && response.group.id) {
      setError(false);
      setMessage(t("GroupsPage.Msgs.Deleted"));
      refetch();
    } else {
      setError(true);
      setMessage(t("GroupsPage.Msgs.ErrorDeleted"));
    }
    window.scrollTo(0, 0);
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={GROUP_DELETE_MUTATION}
        variables={{
          groupId: rowData.id,
        }}
        onCompleted={({ deleteGroup }: any) => {
          deleted(deleteGroup);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              const r = window.confirm(
                t("GroupsPage.Msgs.ConfirmDelete").replace(
                  "%NAME%",
                  rowData.name_en || rowData.name_es || rowData.name_ca
                )
              );

              if (r === true) {
                mutation();
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setNameEN(rowData.name_en);
          setNameES(rowData.name_es);
          setNameCA(rowData.name_ca);
          setOrder(rowData.order);
          setDisabled(rowData.disabled);
          setAlwaysEnabled(rowData.alwaysEnabled);
          if (rowData.from) {
            setTimeFrom(moment(rowData.from, FORMAT_TIME).toDate());
          }
          if (rowData.to) {
            setTimeTo(moment(rowData.to, FORMAT_TIME).toDate());
          }
          setDays(
            (rowData.days || "").split(",").filter((val) => val.length) || []
          );
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const validators: FieldValidator[] = [
    {
      field: nameEN + nameES + nameCA,
      message: t("GroupsPage.Msgs.EnterName"),
    },
    {
      field: String(order),
      message: t("GroupsPage.Msgs.EnterOrder"),
    },
  ];

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? GROUP_UPDATE_MUTATION : GROUP_CREATE_MUTATION}
        variables={{
          nameEN,
          nameES,
          nameCA,
          groupId: editId,
          order,
          days: days.join(","),
          from: timeFrom ? moment(timeFrom).format(FORMAT_TIME) : undefined,
          to: timeTo ? moment(timeTo).format(FORMAT_TIME) : undefined,
          alwaysEnabled: isAlwaysEnabled,
          disabled: isDisabled,
        }}
        onCompleted={({ createGroup, updateGroup }: any) => {
          upserted(createGroup || updateGroup);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? t("Update") : t("Create")}
            icon="pi pi-pencil"
            disabled={!!validateForm(validators).length}
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const onChange = (setter: any) => (e) => {
    setter(e.value || e.target.value);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label={t("Create")}
          icon="pi pi-external-link"
          onClick={() => {
            resetFields();
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? t("Edit") : t("Add")}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          {selectedLanguages.en ? (
            <Accordion className="p-col-12">
              <AccordionTab header="English">
                <div className="p-col-12">{t("GroupsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("GroupsPage.Name")}
                    value={nameEN}
                    onChange={onChange(setNameEN)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.es ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Español">
                <div className="p-col-12">{t("GroupsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("GroupsPage.Name")}
                    value={nameES}
                    onChange={onChange(setNameES)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.ca ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Català">
                <div className="p-col-12">{t("GroupsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("GroupsPage.Name")}
                    value={nameCA}
                    onChange={onChange(setNameCA)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          <div className="p-col-12">{t("GroupsPage.AlwaysEnabled")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={isAlwaysEnabled}
              onChange={onChange(setAlwaysEnabled)}
            />
          </div>
          <div className="p-col-12">{t("GroupsPage.Disabled")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={isDisabled}
              onChange={onChange(setDisabled)}
            />
          </div>
          <div className="p-col-12">{t("GroupsPage.Days")}:</div>
          <div className="p-col-12">
            <MultiSelect
              value={days}
              optionLabel="id"
              itemTemplate={({ id }) => t(`Days.${id}`)}
              selectedItemTemplate={(option: any) => {
                return option ? (
                  <span>{t(`Days.${option}`)} </span>
                ) : (
                  <div>{t(`Select`)}</div>
                );
              }}
              optionValue="id"
              options={LIST_DAYS}
              onChange={(e) => {
                setDays(e.value || []);
              }}
              placeholder={t("GroupsPage.SelectDays")}
            />
          </div>
          <div className="p-col-12">{t("GroupsPage.From")}:</div>
          <div className="p-col-12">
            <Calendar
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              value={timeFrom}
              onChange={(e) => {
                setTimeFrom(e.value);
              }}
            ></Calendar>
          </div>
          <div className="p-col-12">{t("GroupsPage.To")}:</div>
          <div className="p-col-12">
            <Calendar
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              value={timeTo}
              onChange={(e) => setTimeTo(e.value)}
            ></Calendar>
          </div>
          <div className="p-col-12">{t("GroupsPage.Order")}:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <DataTable
          responsive={true}
          value={data}
          selectionMode="single"
          style={{ textAlign: "center" }}
          selection={dataTableSelection}
          onSelectionChange={(event: any) => setDataTableSelection(event.value)}
          paginator={true}
          rows={20}
        >
          {defaultLanguage === "en" ? (
            <Column field="name_en" header={t("GroupsPage.Name")} />
          ) : null}
          {defaultLanguage === "es" ? (
            <Column field="name_es" header={t("GroupsPage.Name")} />
          ) : null}
          {defaultLanguage === "ca" ? (
            <Column field="name_ca" header={t("GroupsPage.Name")} />
          ) : null}
          <Column
            field="order"
            header={t("GroupsPage.Order")}
            sortable={true}
          />
          <Column
            field="id"
            header={t("Edit")}
            sortable={false}
            body={editColumn}
          />
          <Column
            field="id"
            header={t("Delete")}
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
