import gql from "graphql-tag";

export const GROUPS_QUERY = gql`
  query Groups {
    groups {
      id
      name_en
      name_es
      name_ca
      order
      from
      to
      alwaysEnabled
      disabled
      days
    }
  }
`;
