import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLClient } from "entities/client";

import { CLIENT_QUERY } from "graphql/queries/clients/client";

import { QRCode } from "components/QRCode";

const QRCodeContainer = () => {
  return (
    <div>
      <Query query={CLIENT_QUERY}>
        {({ data: { clients }, refetch }: QueryResponse<GQLClient>) => {
          return (
            <QRCode
              data={(clients || [{} as GQLClient])[0]}
              refetch={refetch}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default QRCodeContainer;
