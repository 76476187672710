import React from "react";
import { useTranslation } from "react-i18next";

import GroupsContainer from "containers/Groups";

export const PageGroup: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("GroupsPage.PageTitle")}</h1>
            <GroupsContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
