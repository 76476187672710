import {
  IMenu,
  visibleIfClient,
  SUPER_ADMIN,
  SUPER_USER,
  CLIENT_USER,
} from "./menu";

export const MAIN_MENU_STORE_OPTIONS: IMenu = [
  {
    label: "Dashboard",
    icon: "pi pi-fw pi-chart-bar",
    to: "/",
    visible: visibleIfClient,
  },
  {
    label: "NotificationPopup",
    icon: "pi pi-fw pi-tags",
    to: "/notifications",
    visible: visibleIfClient,
  },
  {
    label: "Menu",
    icon: "pi pi-fw pi-inbox",
    to: "/menu",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "QRCode",
    icon: "pi pi-fw pi-print",
    to: "/qrcode",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "Groups",
    icon: "pi pi-fw pi-th-large",
    to: "/groups",
    className: "tour-menu",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "Categories",
    icon: "pi pi-fw pi-table",
    to: "/categories",
    className: "tour-category",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "Products",
    icon: "pi pi-fw pi-shopping-cart",
    to: "/products",
    className: "tour-product",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "Branding",
    icon: "pi pi-fw pi-palette",
    to: "/setup/branding",
    className: "tour-branding",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
  {
    label: "Settings",
    icon: "pi pi-fw pi-cog",
    to: "/setup/settings",
    className: "tour-settings",
    visible: visibleIfClient,
    roles: [SUPER_ADMIN, SUPER_USER, CLIENT_USER],
  },
];
