import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";

interface QueryProps {
  children: any;
  query: DocumentNode;
  id?: string;
  date?: string;
}
const Query = ({ children, query, id, date }: QueryProps) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { id, date },
    fetchPolicy: "no-cache",
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data, refetch });
};

export default Query;
