import React from "react";
import { useTranslation } from "react-i18next";

import ClientsContainer from "containers/Clients";

export const PageClients: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("ClientsPage.PageTitle")}</h1>
            <ClientsContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
