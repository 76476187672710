import gql from "graphql-tag";

export const NOTIFICATIONS_CREATE_MUTATION = gql`
  mutation CreateNotificationsPopup(
    $title: String
    $description: String
    $price: String
    $active: Boolean
    $from: Time
    $to: Time
  ) {
    createNotificationsPopup(
      input: {
        data: {
          title: $title
          from: $from
          to: $to
          description: $description
          price: $price
          active: $active
        }
      }
    ) {
      notification {
        id
      }
    }
  }
`;

export const NOTIFICATIONS_UPDATE_MUTATION = gql`
  mutation UpdateNotificationsPopup(
    $notificationId: ID!
    $title: String
    $description: String
    $price: String
    $active: Boolean
    $from: Time
    $to: Time
  ) {
    updateNotificationsPopup(
      input: {
        where: { id: $notificationId }
        data: {
          title: $title
          from: $from
          to: $to
          description: $description
          price: $price
          active: $active
        }
      }
    ) {
      notification {
        id
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_ACTIVE_MUTATION = gql`
  mutation UpdateNotificationsPopup($notificationId: ID!, $active: Boolean) {
    updateNotificationsPopup(
      input: { where: { id: $notificationId }, data: { active: $active } }
    ) {
      notification {
        id
      }
    }
  }
`;

export const NOTIFICATIONS_DELETE_MUTATION = gql`
  mutation DeleteNotificationsPopup($notificationId: ID!) {
    deleteNotificationsPopup(input: { where: { id: $notificationId } }) {
      notification {
        id
      }
    }
  }
`;
