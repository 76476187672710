import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import client from "./utils/apolloClient";

ReactDOM.render(
  <RecoilRoot>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ScrollToTop>
            <App></App>
          </ScrollToTop>
        </ApolloProvider>
      </BrowserRouter>
    </I18nextProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
