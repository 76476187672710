import gql from "graphql-tag";

export const BRANDING_QUERY = gql`
  query Branding {
    clients {
      adminUser {
        standard
        premium
      }
    }

    brandings {
      id
      title_en
      title_es
      title_ca
      description_en
      description_es
      description_ca
      otherInformation_en
      otherInformation_es
      otherInformation_ca
      web
      facebook
      instagram
      tripAdvisor
      wifiName
      wifiPassword
      currency
      metaTagImage {
        url
      }
      metaTagTitle
      metaTagDescription
      googleMapsLink
      whatsapp
      whatsappOrder
      logo {
        url
      }
      colorSchema {
        id
        name
      }
      customColor
      headerSectionBackgroundColor
      headerSectionTextColor
      mainMenuBackgroundColor
      mainMenuTextColor
      categoryMenuBackgroundColor
      categoryMenuTextColor
      productBackgroundColor
      backgroundHighlightedProductColor
      labelBoxBackgroundColor
      labelBoxTextColor
      notAvailableBackgroundColor
      notAvailableTextColor
      textTitleColor
      textDescriptionColor
      priceBackgroundColor
      textPriceColor
      popupBackgroundColor
      generalTextColor
      topBackgroundColor
      bottomBackgroundColor
      headerBackgroundColor
      headerFontColor
      backgroundLightyColor
    }

    colorSchemas {
      id
      name
      previewImage {
        url
      }
      headerSectionBackgroundColor
      headerSectionTextColor
      mainMenuBackgroundColor
      mainMenuTextColor
      categoryMenuBackgroundColor
      categoryMenuTextColor
      productBackgroundColor
      backgroundHighlightedProductColor
      labelBoxBackgroundColor
      labelBoxTextColor
      notAvailableBackgroundColor
      notAvailableTextColor
      textTitleColor
      textDescriptionColor
      priceBackgroundColor
      textPriceColor
      popupBackgroundColor
      generalTextColor
      topBackgroundColor
      bottomBackgroundColor
      headerBackgroundColor
      headerFontColor
      backgroundLightyColor
    }

    currencies {
      name
      symbol
    }
  }
`;
