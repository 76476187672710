import React, { memo } from "react";
import moment from "moment";

import { Chart } from "primereact/chart";

import { GQLDashboard } from "entities/dashboard";
import { useTranslation } from "react-i18next";
import { GQLDailyVisits } from "entities/dailyVisits";
import { DATE_FORMAT } from "config/constants";

interface DashboardProps {
  data?: GQLDashboard;
  refetch?: any;
}

export const Dashboard: React.FC<DashboardProps> = memo(({ data }) => {
  const { t } = useTranslation();

  const chartData = {
    labels: (data.visitors || [])
      .map(({ register_date }: GQLDailyVisits) =>
        moment(register_date).format(DATE_FORMAT)
      )
      .reverse(),
    datasets: [
      {
        label: t("DashboardPage.ChartTitle"),
        data: (data.visitors || [])
          .map(({ count }: GQLDailyVisits) => parseInt(count, 10))
          .reverse(),
        backgroundColor: "#42A5F5",
        borderColor: "#42A5F5",
      },
    ],
  };

  return (
    <>
      <div className="p-col-12 p-lg-12">{t("DashboardPage.Historical")}</div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">{t("DashboardPage.Products")}</span>
          <span className="detail">{t("DashboardPage.TotalProducts")}</span>
          <span className="count visitors">{data.totalProducts.length}</span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">{t("DashboardPage.Unavailable")}</span>
          <span className="detail">
            {t("DashboardPage.UnavailableProducts")}
          </span>
          <span className="count purchases">
            {data.totalUnavailableProducts.length}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">{t("DashboardPage.Scans")}</span>
          <span className="detail">
            {data.visitors[0] ? data.visitors[0].register_date : "-"}
          </span>
          <span className="count revenue">
            {data.visitors[0] ? data.visitors[0].count : "-"}
          </span>
        </div>
      </div>

      <div className="p-col-12">
        <Chart type="bar" data={chartData} />
      </div>
    </>
  );
});
