import { atom } from "recoil";

export const languagesState = atom({
  key: "languages",
  default: {
    en: false,
    es: true,
    ca: false,
    loaded: false,
  },
});
