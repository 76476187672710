import gql from "graphql-tag";

export const SETTINGS_QUERY = gql`
  query Settings {
    settings {
      id
      en
      es
      ca
      defaultLanguage
    }

    clients {
      public
      uniqueName
    }
  }
`;
