import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLGroup } from "entities/group";

import { GROUPS_QUERY } from "graphql/queries/groups/groups";

import { Groups } from "components/Groups";

const GroupsContainer = () => {
  return (
    <div>
      <Query query={GROUPS_QUERY}>
        {({ data: { groups }, refetch }: QueryResponse<GQLGroup>) => {
          return <Groups data={groups} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default GroupsContainer;
