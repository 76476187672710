import gql from "graphql-tag";

export const NOTIFICATIONS_QUERY = gql`
  query NotificationsData {
    notificationsPopups {
      id
      title
      description
      price
      image {
        url
      }
      active
      from
      to
    }
  }
`;
