import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";

import {
  CATEGORY_CREATE_MUTATION,
  CATEGORY_UPDATE_MUTATION,
  CATEGORY_DELETE_MUTATION,
} from "graphql/mutations/categories";

import { GQLGroup } from "entities/group";
import { GQLCategory } from "entities/category";
import { validateForm, FieldValidator } from "utils/validators";
import { languagesState } from "atoms/languages";
import { defaultLanguageState } from "atoms/defaultLanguage";

interface CategoriesProps {
  data: GQLCategory[];
  allGroups: GQLGroup[];
  refetch: any;
}

export const Categories: React.FC<CategoriesProps> = ({
  data,
  allGroups,
  refetch,
}) => {
  const { t } = useTranslation();
  const selectedLanguages = useRecoilValue(languagesState);
  const defaultLanguage = useRecoilValue(defaultLanguageState);
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [nameEN, setNameEN] = useState("");
  const [nameES, setNameES] = useState("");
  const [nameCA, setNameCA] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionES, setDescriptionES] = useState("");
  const [descriptionCA, setDescriptionCA] = useState("");
  const [groups, setGroups] = useState(null);
  const [order, setOrder] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [dataTableSelection, setDataTableSelection] = useState(null);

  const resetFields = () => {
    setNameEN("");
    setNameES("");
    setNameCA("");
    setDescriptionEN("");
    setDescriptionES("");
    setDescriptionCA("");
    setGroups(null);
    setOrder(0);
    setEditId(null);
  };

  const upserted = (response: any) => {
    if (response && response.category && response.category.id) {
      setError(false);
      if (editId) {
        setMessage(t("CategoriesPage.Msgs.Updated"));
      } else {
        setMessage(t("CategoriesPage.Msgs.Created"));
      }
      resetFields();
      refetch();
    } else {
      setError(true);
      if (editId) {
        setMessage(t("CategoriesPage.Msgs.ErrorUpdated"));
      } else {
        setMessage(t("CategoriesPage.Msgs.ErrorCreated"));
      }
    }
    window.scrollTo(0, 0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.category && response.category.id) {
      setError(false);
      setMessage(t("CategoriesPage.Msgs.Deleted"));
      refetch();
    } else {
      setError(true);
      setMessage(t("CategoriesPage.Msgs.ErrorDeleting"));
    }
    window.scrollTo(0, 0);
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={CATEGORY_DELETE_MUTATION}
        variables={{
          categoryId: rowData.id,
        }}
        onCompleted={({ deleteCategory }: any) => {
          deleted(deleteCategory);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              const r = window.confirm(
                t("CategoriesPage.Msgs.ConfirmDelete").replace(
                  "%NAME%",

                  rowData.name_en || rowData.name_es || rowData.name_ca
                )
              );

              if (r === true) {
                mutation();
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setNameEN(rowData.name_en);
          setNameES(rowData.name_es);
          setNameCA(rowData.name_ca);
          setDescriptionEN(rowData.description_en);
          setDescriptionES(rowData.description_es);
          setDescriptionCA(rowData.description_ca);
          setGroups((rowData.groups || []).map(({ id }) => id));
          setOrder(rowData.order);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const validators: FieldValidator[] = [
    {
      field: nameEN + nameES + nameCA,
      message: t("CategoriesPage.Msgs.CompleteName"),
    },
    {
      field: String(order),
      message: t("CategoriesPage.Msgs.CompleteOrder"),
    },
  ];

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? CATEGORY_UPDATE_MUTATION : CATEGORY_CREATE_MUTATION}
        variables={{
          nameEN,
          nameES,
          nameCA,
          descriptionEN,
          descriptionES,
          descriptionCA,
          groups,
          categoryId: editId,
          order,
        }}
        onCompleted={({ createCategory, updateCategory }: any) => {
          upserted(createCategory || updateCategory);
        }}
      >
        {(mutation: any) => (
          <Button
            label={
              editId ? t("CategoriesPage.Update") : t("CategoriesPage.Create")
            }
            icon="pi pi-pencil"
            disabled={!!validateForm(validators).length}
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const dropdownGroups = allGroups.map((group: GQLGroup) => ({
    ...group,
    name: group.name_es || group.name_en || group.name_ca,
  }));

  const onChange = (setter: any) => (e) => {
    setter(e.value || e.target.value);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label={t("CategoriesPage.Create")}
          icon="pi pi-external-link"
          onClick={() => {
            resetFields();
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? t("Edit") : t("Add")}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          {selectedLanguages.en ? (
            <Accordion className="p-col-12">
              <AccordionTab header="English">
                <div className="p-col-12">{t("CategoriesPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Name")}
                    value={nameEN}
                    onChange={onChange(setNameEN)}
                  />
                </div>
                <div className="p-col-12">
                  {t("CategoriesPage.Description")}:
                </div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Description")}
                    value={descriptionEN}
                    onChange={onChange(setDescriptionEN)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.es ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Español">
                <div className="p-col-12">{t("CategoriesPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Name")}
                    value={nameES}
                    onChange={onChange(setNameES)}
                  />
                </div>
                <div className="p-col-12">
                  {t("CategoriesPage.Description")}:
                </div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Description")}
                    value={descriptionES}
                    onChange={onChange(setDescriptionES)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.ca ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Català">
                <div className="p-col-12">{t("CategoriesPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Name")}
                    value={nameCA}
                    onChange={onChange(setNameCA)}
                  />
                </div>
                <div className="p-col-12">
                  {t("CategoriesPage.Description")}:
                </div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("CategoriesPage.Description")}
                    value={descriptionCA}
                    onChange={onChange(setDescriptionCA)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}

          <div className="p-col-12">{t("CategoriesPage.Group")}:</div>
          <div className="p-col-12">
            <MultiSelect
              value={groups}
              optionLabel="name"
              optionValue="id"
              options={dropdownGroups}
              onChange={(e) => {
                setGroups(e.value);
              }}
              placeholder={t("CategoriesPage.SelectGroup")}
            />
          </div>
          <div className="p-col-12">{t("CategoriesPage.Order")}:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <DataTable
          responsive={true}
          value={data}
          selectionMode="single"
          selection={dataTableSelection}
          onSelectionChange={(event: any) => setDataTableSelection(event.value)}
        >
          {defaultLanguage === "en" ? (
            <Column field="name_en" header={t("CategoriesPage.Name")} />
          ) : null}
          {defaultLanguage === "es" ? (
            <Column field="name_es" header={t("CategoriesPage.Name")} />
          ) : null}
          {defaultLanguage === "ca" ? (
            <Column field="name_ca" header={t("CategoriesPage.Name")} />
          ) : null}
          <Column
            field="groups"
            header={t("CategoriesPage.Group")}
            body={(rowData: any) =>
              (rowData.groups || [])
                .map((group) => group[`name_${defaultLanguage.toLowerCase()}`])
                .join(",")
            }
          />
          <Column
            field="order"
            header={t("CategoriesPage.Order")}
            sortable={true}
          />
          <Column
            field="id"
            header={t("Edit")}
            sortable={false}
            body={editColumn}
          />
          <Column
            field="id"
            header={t("Delete")}
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
