import Axios, { AxiosResponse } from "axios";
import { Auth } from "utils/auth";

class Api {
  static getAuthHeaders() {
    const token = Auth.getToken();
    const client = Auth.getClient();
    return {
      authorization: token ? `Bearer ${token}` : "",
      store: client ? `Bearer ${client}` : "",
    };
  }

  static addProductPrice(productId) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/product-prices`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          product: productId,
          label: "...",
          price: "...",
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static editProductPrice(productId, label, price) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/product-prices/${productId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          label,
          price,
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static deleteProductPrice(productId) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "delete",
        url: `${process.env.REACT_APP_BACKEND_URL}/product-prices/${productId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static findUserByEmail(email: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users?email_eq=${email}&_limit=1`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static getSettings() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/settings`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static getClients() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/clients`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/me`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static async updateUser(clientId) {
    // const fullUser = await Api.getUser();

    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/settings`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static confirmAccount(token: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/confirm/${token}`,
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static checkUniqueName(name: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/checkUniqueName/${name}`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data.unique);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static setTourRead() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/settings/000`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          tutorial: true,
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static findBrandImage() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/brandings`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve((data && data[0] && data[0].logo.url) || "");
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}

export default Api;
