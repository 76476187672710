import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

import { Dropdown } from "primereact/dropdown";

import { clientState } from "atoms/client";
import Api from "api";
import { Auth } from "utils/auth";

export const ClientSelector = () => {
  const [client, setClient] = useRecoilState(clientState);
  const [clients, setClients] = useState([]);
  const [localStorageTry, setLocalStorageTry] = useState(false);

  const changeClient = (e: any) => {
    Auth.setClient(e.value);
    setClient(e.value);
  };

  useEffect(() => {
    if (!clients.length) {
      Api.getClients().then((clientList: any) => {
        setClients(clientList);
      });
    }
  }, [clients, setClients]);

  useEffect(() => {
    if (clients.length && !localStorageTry && !client) {
      if (!client) {
        const lsClient: any = Auth.getClient();

        if (lsClient) {
          if (clients.find((c) => c.id === parseInt(lsClient, 10))) {
            setClient(parseInt(lsClient, 10) as any);
          } else {
            Auth.removeClient();
          }
        }
      }
      setLocalStorageTry(true);
    }
  }, [client, localStorageTry, setLocalStorageTry, setClient, clients]);

  return (
    <div className="client-selector">
      <Dropdown
        value={client}
        optionValue="id"
        optionLabel="name"
        options={clients}
        onChange={changeClient}
        placeholder="Select a Store"
      />
    </div>
  );
};
