import React, { useState } from "react";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import moment from "moment";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";

import {
  NOTIFICATIONS_CREATE_MUTATION,
  NOTIFICATIONS_UPDATE_MUTATION,
  NOTIFICATIONS_DELETE_MUTATION,
} from "graphql/mutations/notifications";
import { Auth } from "utils/auth";
import { GQLNotificationPopup } from "entities/notificationPopup";
import { validateForm, FieldValidator } from "utils/validators";

import "react-image-crop/lib/ReactCrop.scss";

const FORMAT_TIME = "HH:mm:ss.SSS";

interface NotificationsProps {
  data: GQLNotificationPopup[];
  refetch: any;
}

export const Notifications: React.FC<NotificationsProps> = ({
  data,
  refetch,
}) => {
  const { t } = useTranslation();
  const [imageRef, setImageRef] = useState<any>(null);
  const [originalFile, setimeToriginalFile] = useState<any>(null);

  const [cropImage, setCropImage] = useState<any>({
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1,
    },
    croppedImageUrl: null,
  });

  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(false);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [price, setPrice] = useState<any>(null);

  const [pendingFiles, setPendingFiles] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const resetFields = () => {
    setTitle("");
    setDescription("");
    setActive(true);
    setTimeFrom(false);
    setTimeTo(false);
    setPrice(null);
    setCurrentImage(null);
    setEditId(null);
    setImageRef(null);
    setimeToriginalFile(null);
    setCropImage({
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1,
      },
      croppedImageUrl: null,
    });
    setPendingFiles(null);
  };

  const upserted = (response: any) => {
    if (response && response.notification && response.notification.id) {
      setError(false);
      if (editId) {
        setMessage(t("NotificationsPage.Msgs.Updated"));
      } else {
        setMessage(t("NotificationsPage.Msgs.Created"));
      }
      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "notificationPopup");
        formData.set("field", "image");
        const file = pendingFiles[0];
        formData.append("files", file, file.name);
        formData.set("refId", response.notification.id);
        const timeToken = Auth.getToken();
        const client = Auth.getClient();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: timeToken ? `Bearer ${timeToken}` : "",
            stimeTore: client ? `Bearer ${client}` : "",
          },
        })
          .then(() => {
            refetch();
          })
          .finally(() => {
            setPendingFiles(null);
          });
      } else {
        refetch();
      }
    } else {
      setError(true);
      if (editId) {
        setMessage(t("NotificationsPage.Msgs.ErrorUpdated"));
      } else {
        setMessage(t("NotificationsPage.Msgs.ErrorCreated"));
      }
    }
    resetFields();
    window.scrollTo(0, 0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.notification && response.notification.id) {
      setError(false);
      setMessage(t("NotificationsPage.Msgs.Deleted"));
      refetch();
    } else {
      setError(true);
      setMessage(t("NotificationsPage.Msgs.ErrorDeleted"));
    }
    window.scrollTo(0, 0);
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={NOTIFICATIONS_DELETE_MUTATION}
        variables={{
          notificationId: rowData.id,
        }}
        onCompleted={({ deleteProduct }: any) => {
          deleted(deleteProduct);
        }}
      >
        {(mutation: any) => (
          <Button
            type="buttimeTon"
            icon="pi pi-trash"
            className="p-buttimeTon-danger"
            onClick={() => {
              const r = window.confirm(
                t("NotificationsPage.Msgs.ConfirmDelete").replace(
                  "%NAME%",
                  rowData.title
                )
              );

              if (r === true) {
                mutation();
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="buttimeTon"
        icon="pi pi-pencil"
        className="p-buttimeTon-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setTitle(rowData.name_en);
          setDescription(rowData.description_en);
          setPrice(rowData.price);
          setActive(rowData.active);
          if (rowData.from) {
            setTimeFrom(moment(rowData.from, FORMAT_TIME).toDate());
          }
          if (rowData.to) {
            setTimeTo(moment(rowData.to, FORMAT_TIME).toDate());
          }
          setCurrentImage(rowData.image ? rowData.image.url : null);
          setShowAdd(true);
        }}
      />
    );
  };

  const validators: FieldValidator[] = [
    {
      field: title,
      message: t("NotificationsPage.Msgs.CompleteTitle"),
    },
  ];

  const addFooter = () => {
    return (
      <Mutation
        mutation={
          editId ? NOTIFICATIONS_UPDATE_MUTATION : NOTIFICATIONS_CREATE_MUTATION
        }
        variables={{
          title,
          description,
          price: String(price || ""),
          active,
          timeFrom: timeFrom ? moment(timeFrom).format(FORMAT_TIME) : undefined,
          timeTo: timeTo ? moment(timeTo).format(FORMAT_TIME) : undefined,
          notificationId: editId,
        }}
        onCompleted={({ createNotification, updateNotification }: any) => {
          upserted(createNotification || updateNotification);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? t("Update") : t("Create")}
            icon="pi pi-pencil"
            disabled={!!validateForm(validators).length}
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const imageColumn = (rowData: any, column: any) => {
    return rowData[column.field] && rowData[column.field].url ? (
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}${rowData[column.field].url}`}
        width="100%"
        height="autimeTo"
        alt=""
      />
    ) : null;
  };

  const setFormData = (event: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setCropImage({ ...cropImage, src: fileReader.result });
    };
    setimeToriginalFile(event.files[0]);
    fileReader.readAsDataURL(event.files[0]);
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (crop) => {
    setCropImage({ ...cropImage, crop });
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLToFile(reader.result, originalFile.name);
      };
    });
  };

  const onCropComplete = (crop) => {
    if (imageRef && cropImage.crop.width && cropImage.crop.height) {
      const croppedImageUrl = getCroppedImg(imageRef, crop);
      setCropImage({ ...cropImage, croppedImageUrl });
    }
  };

  const dataURLToFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCropImage({ ...cropImage, croppedImage });
    setPendingFiles([croppedImage]);
  };

  const onChange = (setter: any) => (e) => {
    setter(e.value || e.target.value);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label={t("Create")}
          icon="pi pi-external-link"
          onClick={() => {
            resetFields();
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? t("Edit") : t("Add")}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          resetFields();
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">{t("NotificationsPage.Title")}:</div>
          <div className="p-col-12">
            <InputText
              placeholder={t("NotificationsPage.Title")}
              value={title}
              onChange={onChange(setTitle)}
            />
          </div>
          <div className="p-col-12">{t("NotificationsPage.Description")}:</div>
          <div className="p-col-12">
            <InputText
              placeholder={t("NotificationsPage.Description")}
              value={description}
              onChange={onChange(setDescription)}
            />
          </div>
          <div className="p-col-12">{t("NotificationsPage.Price")}:</div>
          <div className="p-col-12">
            <InputNumber
              placeholder={t("NotificationsPage.Price")}
              value={price}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              onChange={onChange(setPrice)}
            />
          </div>
          <div className="p-col-12">{t("NotificationsPage.From")}:</div>
          <div className="p-col-12">
            <Calendar
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              value={timeFrom}
              onChange={(e) => {
                setTimeFrom(e.value);
              }}
            ></Calendar>
          </div>
          <div className="p-col-12">{t("NotificationsPage.To")}:</div>
          <div className="p-col-12">
            <Calendar
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              value={timeTo}
              onChange={(e) => setTimeTo(e.value)}
            ></Calendar>
          </div>
          <div className="p-col-12">{t("NotificationsPage.Active")}:</div>
          <div className="p-col-12">
            <InputSwitch checked={active} onChange={onChange(setActive)} />
          </div>
          <div className="p-col-12">{t("NotificationsPage.Image")}:</div>
          {currentImage ? (
            <div className="p-col-12">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${currentImage}`}
                width="100%"
                height="autimeTo"
                alt=""
              />
            </div>
          ) : null}
          <div className="p-col-12">
            <FileUpload
              name="image"
              url={`${process.env.REACT_APP_BACKEND_URL}/setup/invalid`}
              accept="image/*"
              mode="basic"
              auto={true}
              onError={setFormData}
            />
            {cropImage.src && (
              <ReactCrop
                src={cropImage.src}
                crop={cropImage.crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
          </div>
        </div>
      </Dialog>
      <div className="card card-w-name">
        <DataTable
          responsive={true}
          value={data}
          selectionMode="single"
          paginator={true}
          rows={20}
          style={{ textAlign: "center" }}
        >
          <Column field="title" header={t("NotificationsPage.Title")} />
          <Column
            field="description"
            header={t("NotificationsPage.Description")}
          />
          <Column field="price" header="Price" />
          <Column field="from" header="From" />
          <Column field="to" header="To" />
          <Column
            field="image"
            header={t("NotificationsPage.Image")}
            sortable={false}
            body={imageColumn}
          />
          <Column
            field="id"
            header={t("Edit")}
            sortable={false}
            body={editColumn}
          />
          <Column
            field="id"
            header={t("Delete")}
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
