import React from "react";
import { useTranslation } from "react-i18next";

import QRCodeContainer from "containers/QRCode";

import "./QRCode.scss";

export const PageQRCode: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-fluid qr-code">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>{t("QRCodePage.PageTitle")}</h1>
            <QRCodeContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
