import gql from "graphql-tag";

export const DASHBOARD_QUERY = gql`
  query Dashboard {
    totalProducts: products {
      id
    }

    totalUnavailableProducts: products(where: { available: false }) {
      id
    }

    visitors: dailyVisits(limit: 7, sort: "register_date:desc") {
      register_date
      count
    }
  }
`;
