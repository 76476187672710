import gql from "graphql-tag";

export const MENU_QUERY = gql`
  query Menu {
    products(sort: "name:asc") {
      id
      name_en
      name_es
      name_ca
      description_en
      description_es
      description_ca
      price
      available
      mainPage
      hidden
      categories {
        name_en
        name_es
        name_ca
      }
    }
  }
`;
