import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Auth } from "utils/auth";
import { NavLink } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "config/constants";
import { languagesState } from "atoms/languages";
import { defaultLanguageState } from "atoms/defaultLanguage";
import Api from "api";
import { clientState } from "atoms/client";
import { userState } from "atoms/user";

interface AppTopBarProps {
  onToggleMenu: any;
  openTour: any;
}

export const AppTopBar: React.FC<AppTopBarProps> = ({
  onToggleMenu,
  openTour,
}) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [languages, setLanguages] = useRecoilState(languagesState);
  const [client, setClient] = useRecoilState(clientState);
  const [user, setUser] = useRecoilState(userState);
  const setDefaultLanguage = useSetRecoilState(defaultLanguageState);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.value);
    setLanguage(e.value);
  };

  useEffect(() => {
    if (client && !languages.loaded) {
      Api.getSettings().then((data) => {
        setLanguages({
          loaded: true,
          es: data[0] ? data[0].es : false,
          en: data[0] ? data[0].en : true,
          ca: data[0] ? data[0].ca : false,
        });

        if (!data[0] || !data[0].tutorial) {
          openTour();
        }

        setDefaultLanguage(data[0] ? data[0].defaultLanguage : "en");
      });
    }
  }, [
    languages,
    setLanguages,
    setDefaultLanguage,
    setClient,
    openTour,
    client,
  ]);

  useEffect(() => {
    if (!user.loaded) {
      Api.getUser().then((data: any) => {
        const { email, klivrRole, premium, standard } = data;
        setUser({
          email,
          role: klivrRole,
          premium,
          standard,
          loaded: true,
        });
      });
    }
  }, [user, setUser]);

  return (
    <div className="layout-topbar clearfix">
      {Auth.isAuthenticated() ? (
        <>
          <button className="p-link layout-menu-button" onClick={onToggleMenu}>
            <span className="pi pi-bars" />
          </button>
          <div className="layout-topbar-icons">
            <h2 className="user-name">{user.loaded ? user.email : ""}</h2>
            <Dropdown
              value={language}
              options={SUPPORTED_LANGUAGES}
              onChange={changeLanguage}
              placeholder="Select a City"
            />
            <button className="p-link" onClick={openTour}>
              <span className="layout-topbar-item-text">Tour</span>
              <span className="layout-topbar-icon tour-opener pi pi-question-circle" />
            </button>
            <NavLink to="/my-account">
              <button className="p-link">
                <span className="layout-topbar-item-text">User</span>
                <span className="layout-topbar-icon pi pi-user" />
              </button>
            </NavLink>
            <button
              className="p-link"
              onClick={() => {
                Auth.clear();
                window.location.href = "/";
              }}
            >
              <span className="layout-topbar-item-text">Logout</span>
              <span className="layout-topbar-icon pi pi-fw pi-sign-out" />
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};
