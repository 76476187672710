import React from "react";

import Query from "components/Query";
import { QueryResponse } from "entities/query";
import { GQLClient } from "entities/client";

import { CLIENTS_QUERY } from "graphql/queries/clients/clients";

import { Clients } from "components/Clients";

const ClientsContainer = () => {
  return (
    <Query query={CLIENTS_QUERY}>
      {({ data: { clients }, refetch }: QueryResponse<GQLClient>) => {
        return <Clients data={clients} refetch={refetch} />;
      }}
    </Query>
  );
};

export default ClientsContainer;
