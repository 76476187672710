import gql from "graphql-tag";

export const CLIENT_CREATE_MUTATION = gql`
  mutation CreateClient(
    $name: String
    $adminUser: String!
    $public: String!
    $uniqueName: String
  ) {
    createClient(
      input: {
        data: {
          name: $name
          adminUser: $adminUser
          public: $public
          uniqueNmae: $uniqueName
        }
      }
    ) {
      client {
        id
      }
    }
  }
`;

export const CLIENT_UPDATE_MUTATION = gql`
  mutation UpdateClient(
    $clientId: ID!
    $name: String
    $public: String
    $uniqueName: String
  ) {
    updateClient(
      input: {
        where: { id: $clientId }
        data: { name: $name, public: $public, uniqueName: $uniqueName }
      }
    ) {
      client {
        id
      }
    }
  }
`;

export const CLIENT_DELETE_MUTATION = gql`
  mutation DeleteClient($clientId: ID!) {
    deleteClient(input: { where: { id: $clientId } }) {
      client {
        id
      }
    }
  }
`;
