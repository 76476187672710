import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";

import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";

import {
  PRODUCTS_CREATE_MUTATION,
  PRODUCTS_UPDATE_MUTATION,
  PRODUCTS_DELETE_MUTATION,
} from "graphql/mutations/products";
import { Auth } from "utils/auth";
import { GQLCategory } from "entities/category";
import { GQLProduct } from "entities/product";
import { validateForm, FieldValidator } from "utils/validators";
import { languagesState } from "atoms/languages";
import { defaultLanguageState } from "atoms/defaultLanguage";

import "react-image-crop/lib/ReactCrop.scss";
import { GQLProductAttribute } from "entities/productAttribute";
import Api from "api";
interface ProductsProps {
  data: GQLProduct[];
  categories: GQLCategory[];
  productAttributes: GQLProductAttribute[];
  refetch: any;
}

export const Products: React.FC<ProductsProps> = ({
  data,
  categories: allCategories,
  productAttributes: allProductAttributes,
  refetch,
}) => {
  const { t } = useTranslation();
  const [imageRef, setImageRef] = useState<any>(null);
  const [originalFile, setOriginalFile] = useState<any>(null);

  const [cropLogo, setCropLogo] = useState<any>({
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
    croppedImageUrl: null,
  });
  const selectedLanguages = useRecoilValue(languagesState);
  const defaultLanguage = useRecoilValue(defaultLanguageState);
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [nameEN, setNameEN] = useState("");
  const [nameES, setNameES] = useState("");
  const [nameCA, setNameCA] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionES, setDescriptionES] = useState("");
  const [descriptionCA, setDescriptionCA] = useState("");

  const [available, setAvailable] = useState(true);
  const [highlight, setHighlight] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isMainPage, setIsMainPage] = useState(false);
  const [multiplePrice, setMultiplePrice] = useState(false);
  const [multiplePrices, setMultiplePrices] = useState([]);
  const [originalMultiplePrices, setOriginalMultiplePrices] = useState([]);
  const [price, setPrice] = useState<any>(null);
  const [order, setOrder] = useState(0);
  const [categories, setCategories] = useState([]);
  const [productAttributes, setProductAttributes] = useState([]);
  const [pendingFiles, setPendingFiles] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [filterCategoryName, setFilterCategoryName] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const resetFields = () => {
    setNameEN("");
    setNameES("");
    setNameCA("");
    setDescriptionEN("");
    setDescriptionES("");
    setDescriptionCA("");
    setAvailable(true);
    setHighlight(false);
    setIsNew(false);
    setIsMainPage(false);
    setIsHidden(false);
    setPrice(null);
    setCategories([]);
    setProductAttributes([]);
    setCurrentPhoto(null);
    setOrder(0);
    setEditId(null);
    setImageRef(null);
    setOriginalFile(null);
    setCropLogo({
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    });
    setPendingFiles(null);
  };

  const upserted = (response: any) => {
    if (response && response.product && response.product.id) {
      setError(false);
      if (editId) {
        setMessage(t("ProductsPage.Msgs.Updated"));
      } else {
        setMessage(t("ProductsPage.Msgs.Created"));
      }
      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "product");
        formData.set("field", "image");
        const file = pendingFiles[0];
        formData.append("files", file, file.name);
        formData.set("refId", response.product.id);
        const token = Auth.getToken();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token ? `Bearer ${token}` : "",
          },
        })
          .then(() => {
            refetch();
          })
          .finally(() => {
            setPendingFiles(null);
          });
      } else {
        refetch();
      }
    } else {
      setError(true);
      if (editId) {
        setMessage(t("ProductsPage.Msgs.ErrorUpdated"));
      } else {
        setMessage(t("ProductsPage.Msgs.ErrorCreated"));
      }
    }
    resetFields();
    window.scrollTo(0, 0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.product && response.product.id) {
      setError(false);
      setMessage(t("ProductsPage.Msgs.Deleted"));
      refetch();
    } else {
      setError(true);
      setMessage(t("ProductsPage.Msgs.ErrorDeleted"));
    }
    window.scrollTo(0, 0);
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={PRODUCTS_DELETE_MUTATION}
        variables={{
          productId: rowData.id,
        }}
        onCompleted={({ deleteProduct }: any) => {
          deleted(deleteProduct);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              const r = window.confirm(
                t("ProductsPage.Msgs.ConfirmDelete").replace(
                  "%NAME%",
                  rowData.name_en || rowData.name_es || rowData.name_ca
                )
              );

              if (r === true) {
                mutation();
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  const deletePriceColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => {
          deleteMultiplePrice(rowData.label);
        }}
      />
    );
  };

  const addNewMultiplePrice = () => {
    Api.addProductPrice(editId || null).then((resData: any) => {
      setMultiplePrices([
        ...multiplePrices,
        {
          id: resData.id,
          label: "...",
          price: "...",
        },
      ]);
    });
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setNameEN(rowData.name_en);
          setNameES(rowData.name_es);
          setNameCA(rowData.name_ca);
          setDescriptionEN(rowData.description_en);
          setDescriptionES(rowData.description_es);
          setDescriptionCA(rowData.description_ca);
          setPrice(rowData.price);
          setAvailable(rowData.available);
          setHighlight(rowData.highlight);
          setIsNew(rowData.new);
          setIsHidden(rowData.hidden);
          setIsMainPage(rowData.mainPage);
          setMultiplePrice(rowData.multiPrice);
          setMultiplePrices(rowData.product_prices);
          setCategories(
            rowData.categories ? rowData.categories.map(({ id }) => id) : null
          );
          setProductAttributes(
            rowData.product_attributes
              ? rowData.product_attributes.map(({ id }) => id)
              : null
          );
          setOrder(rowData.order);
          setCurrentPhoto(rowData.image ? rowData.image.url : null);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const validators: FieldValidator[] = [
    {
      field: nameEN + nameES + nameCA,
      message: t("ProductsPage.Msgs.CompleteName"),
    },
    {
      field: String(order),
      message: t("ProductsPage.Msgs.CompleteOrder"),
    },
  ];

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? PRODUCTS_UPDATE_MUTATION : PRODUCTS_CREATE_MUTATION}
        variables={{
          nameEN,
          nameES,
          nameCA,
          descriptionEN,
          descriptionES,
          descriptionCA,
          price: String(price || ""),
          available,
          highlight,
          isNew,
          isHidden,
          isMainPage,
          multiplePrice,
          multiplePrices: multiplePrices.map((mp) => mp.id),
          order,
          categories,
          productId: editId,
        }}
        onCompleted={({ createProduct, updateProduct }: any) => {
          upserted(createProduct || updateProduct);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? t("Update") : t("Create")}
            icon="pi pi-pencil"
            disabled={!!validateForm(validators).length}
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const preparedCategories = allCategories.map((cat: GQLCategory) => ({
    ...cat,
    name:
      cat[`name_${defaultLanguage}`] ||
      cat.name_en ||
      cat.name_es ||
      cat.name_ca,
  }));

  const preparedProductAttributes = allProductAttributes.map(
    (productAttribute: GQLProductAttribute) => ({
      ...productAttribute,
      name:
        productAttribute[`name_${defaultLanguage}`] ||
        productAttribute.name_en ||
        productAttribute.name_es ||
        productAttribute.name_ca,
    })
  );

  const dataViewHeader = (
    <div className="p-grid">
      <div className="p-col-12 p-lg-3 filter-container">
        <div style={{ position: "relative" }}>
          <Dropdown
            value={filterCategoryName}
            optionValue="id"
            optionLabel="name"
            showClear={true}
            options={preparedCategories}
            onChange={(e) => {
              setFilterCategoryName(e.value);
            }}
            placeholder={t("ProductsPage.FilterByCategory")}
          />
        </div>
      </div>
    </div>
  );

  const imageColumn = (rowData: any, column: any) => {
    return rowData[column.field] && rowData[column.field].url ? (
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}${rowData[column.field].url}`}
        width="100%"
        height="auto"
        alt=""
      />
    ) : null;
  };

  const setFormData = (event: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setCropLogo({ ...cropLogo, src: fileReader.result });
    };
    setOriginalFile(event.files[0]);
    fileReader.readAsDataURL(event.files[0]);
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (crop) => {
    setCropLogo({ ...cropLogo, crop });
  };

  const onCropComplete = (crop) => {
    if (imageRef && cropLogo.crop.width && cropLogo.crop.height) {
      const croppedImageUrl = getCroppedImg(imageRef, crop);
      setCropLogo({ ...cropLogo, croppedImageUrl });
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, originalFile.name);
      };
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCropLogo({ ...cropLogo, croppedImage });
    setPendingFiles([croppedImage]);
  };

  const onChange = (setter: any) => (e) => {
    setter(e.value || e.target.value);
  };

  const onRowEditMultiplePriceInit = () => {
    setOriginalMultiplePrices(multiplePrices);
  };

  const onRowEditMultiplePriceCancel = () => {
    setMultiplePrices(originalMultiplePrices);
  };

  const onRowEditMultiplePriceDone = ({ data: rowData }: any) => {
    Api.editProductPrice(rowData.id, rowData.label, String(rowData.price));
  };

  const deleteMultiplePrice = (label: string) => {
    const newMultiplePrices = [...multiplePrices];
    const index = newMultiplePrices.findIndex(
      (multPrice) => multPrice.label === label
    );

    if (index) {
      Api.deleteProductPrice(newMultiplePrices[index].id).then(() => {
        newMultiplePrices.splice(index, 1);
        setMultiplePrices(newMultiplePrices);
      });
    }
  };

  const onEditorValueChange = (props, value) => {
    let updatedMultiplePrices = [...multiplePrices];
    updatedMultiplePrices[props.rowIndex][props.field] = value;
    setMultiplePrices(updatedMultiplePrices);
  };

  const inputTextEditor = (props, field, number = false) => {
    return !number ? (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e: any) => onEditorValueChange(props, e.target.value)}
      />
    ) : (
      <InputNumber
        placeholder={t("ProductsPage.Price")}
        value={props.rowData[field]}
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={2}
        onChange={(e: any) => onEditorValueChange(props, e.target.value)}
      />
    );
  };

  const labelEditor = (props) => {
    return inputTextEditor(props, "label");
  };

  const priceEditor = (props) => {
    return inputTextEditor(props, "price", true);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label={t("Create")}
          icon="pi pi-external-link"
          onClick={() => {
            resetFields();
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? t("Edit") : t("Add")}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          resetFields();
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          {selectedLanguages.en ? (
            <Accordion className="p-col-12">
              <AccordionTab header="English">
                <div className="p-col-12">{t("ProductsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Name")}
                    value={nameEN}
                    onChange={onChange(setNameEN)}
                  />
                </div>
                <div className="p-col-12">{t("ProductsPage.Description")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Description")}
                    value={descriptionEN}
                    onChange={onChange(setDescriptionEN)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.es ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Español">
                <div className="p-col-12">{t("ProductsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Name")}
                    value={nameES}
                    onChange={onChange(setNameES)}
                  />
                </div>
                <div className="p-col-12">{t("ProductsPage.Description")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Description")}
                    value={descriptionES}
                    onChange={onChange(setDescriptionES)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          {selectedLanguages.ca ? (
            <Accordion className="p-col-12">
              <AccordionTab header="Català">
                <div className="p-col-12">{t("ProductsPage.Name")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Name")}
                    value={nameCA}
                    onChange={onChange(setNameCA)}
                  />
                </div>
                <div className="p-col-12">{t("ProductsPage.Description")}:</div>
                <div className="p-col-12">
                  <InputText
                    placeholder={t("ProductsPage.Description")}
                    value={descriptionCA}
                    onChange={onChange(setDescriptionCA)}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          ) : null}
          <div className="p-col-12">{t("ProductsPage.MultiplePrice")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={multiplePrice}
              onChange={onChange(setMultiplePrice)}
            />
          </div>
          {!multiplePrice ? (
            <>
              <div className="p-col-12">{t("ProductsPage.Price")}:</div>
              <div className="p-col-12">
                <InputNumber
                  placeholder={t("ProductsPage.Price")}
                  value={price}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  onChange={onChange(setPrice)}
                />
              </div>
            </>
          ) : (
            <Accordion className="p-col-12">
              <AccordionTab header={t("ProductsPage.Prices")}>
                <DataTable
                  value={multiplePrices}
                  editMode="row"
                  dataKey="label"
                  onRowEditSave={onRowEditMultiplePriceDone}
                  onRowEditInit={onRowEditMultiplePriceInit}
                  onRowEditCancel={onRowEditMultiplePriceCancel}
                >
                  <Column
                    field="label"
                    header="Label"
                    editor={labelEditor}
                  ></Column>
                  <Column
                    field="price"
                    header="Price"
                    editor={priceEditor}
                  ></Column>
                  <Column rowEditor field="label" header={t("Edit")}></Column>
                  <Column
                    field="label"
                    header={t("Delete")}
                    body={deletePriceColumn}
                  />
                </DataTable>
                <Button label={t("Add")} onClick={addNewMultiplePrice} />
              </AccordionTab>
            </Accordion>
          )}
          <div className="p-col-12">{t("ProductsPage.Category")}:</div>
          <div className="p-col-12">
            <MultiSelect
              value={categories}
              optionLabel="name"
              optionValue="id"
              options={preparedCategories}
              onChange={(e) => {
                setCategories(e.value);
              }}
              placeholder={t("ProductsPage.SelectCategory")}
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.Attributes")}:</div>
          <div className="p-col-12">
            <MultiSelect
              value={productAttributes}
              optionLabel="name"
              optionValue="id"
              options={preparedProductAttributes}
              onChange={(e) => {
                setProductAttributes(e.value);
              }}
              placeholder={t("ProductsPage.SelectAttributes")}
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.Order")}:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.Available")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={available}
              onChange={onChange(setAvailable)}
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.Highlight")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={highlight}
              onChange={onChange(setHighlight)}
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.New")}:</div>
          <div className="p-col-12">
            <InputSwitch checked={isNew} onChange={onChange(setIsNew)} />
          </div>
          <div className="p-col-12">{t("ProductsPage.MainPage")}:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={isMainPage}
              onChange={onChange(setIsMainPage)}
            />
          </div>
          <div className="p-col-12">{t("ProductsPage.MainPageWarning")}:</div>
          <div className="p-col-12">{t("ProductsPage.Hidden")}:</div>
          <div className="p-col-12">
            <InputSwitch checked={isHidden} onChange={onChange(setIsHidden)} />
          </div>
          <div className="p-col-12">{t("ProductsPage.Image")}:</div>
          {currentPhoto ? (
            <div className="p-col-12">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${currentPhoto}`}
                width="100%"
                height="auto"
                alt=""
              />
            </div>
          ) : null}
          <div className="p-col-12">
            <FileUpload
              name="image"
              url={`${process.env.REACT_APP_BACKEND_URL}/setup/invalid`}
              accept="image/*"
              mode="basic"
              auto={true}
              onError={setFormData}
            />
            {cropLogo.src && (
              <ReactCrop
                src={cropLogo.src}
                crop={cropLogo.crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
          </div>
        </div>
      </Dialog>
      <div className="card card-w-name">
        <DataTable
          responsive={true}
          value={data.filter((product) => {
            return (
              !filterCategoryName ||
              product.categories.find((cat) => cat.id === filterCategoryName)
            );
          })}
          selectionMode="single"
          header={dataViewHeader}
          paginator={true}
          rows={20}
          style={{ textAlign: "center" }}
        >
          {defaultLanguage === "en" ? (
            <Column field="name_en" header={t("ProductsPage.Name")} />
          ) : null}
          {defaultLanguage === "es" ? (
            <Column field="name_es" header={t("ProductsPage.Name")} />
          ) : null}
          {defaultLanguage === "ca" ? (
            <Column field="name_ca" header={t("ProductsPage.Name")} />
          ) : null}
          <Column field="price" header="Price" />
          <Column
            field="category"
            header={t("ProductsPage.Category")}
            body={(rowData: any) =>
              (rowData.categories || [])
                .map((cat) => cat[`name_${defaultLanguage.toLowerCase()}`])
                .join(",")
            }
          />
          <Column
            field="order"
            header={t("ProductsPage.Order")}
            sortable={true}
          />
          <Column
            field="image"
            header={t("ProductsPage.Image")}
            sortable={false}
            body={imageColumn}
          />
          <Column
            field="id"
            header={t("Edit")}
            sortable={false}
            body={editColumn}
          />
          <Column
            field="id"
            header={t("Delete")}
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
