import React from "react";

export default (t: any) => {
  return [
    {
      selector: ".tour-opener",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step1.Welcome")}</h2>
          <p>{t("Tour.Step1.ThanksForJoining")}</p>
          <br />
          <p>{t("Tour.Step1.WhatAreWeGoingToDo")}</p>
          <br />
          <p>{t("Tour.Step1.TourIcon")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-dashboard",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step2.Dashboard")}</h2>
          <p>{t("Tour.Step2.SectionIntro")}</p>
          <br />
          <h3>{t("Tour.Step2.Structure")}</h3>
          <br />
          <p className="tour-medium">{t("Tour.Step2.Menu")}</p>
          <p className="tour-medium">{t("Tour.Step2.Category")}</p>
          <p className="tour-medium">{t("Tour.Step2.Product")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-settings",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step3.Settings")}</h2>
          <p>{t("Tour.Step3.SectionIntro")}</p>
          <br />
          <h3>{t("Tour.Step3.Language")}</h3>
          <br />
          <p>{t("Tour.Step3.Description")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-branding",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step4.Branding")}</h2>
          <p>{t("Tour.Step4.SectionIntro")}</p>
          <br />
          <h3>{t("Tour.Step4.SocialNetworkAndCurrency")}</h3>
          <br />
          <p>{t("Tour.Step4.SocialNetworkAndCurrencyDescription")}</p>
          <br />
          <h3>{t("Tour.Step4.Wifi")}</h3>
          <br />
          <p>{t("Tour.Step4.WifiDescription")}</p>
          <br />
          <h3>{t("Tour.Step4.SchemaColor")}</h3>
          <br />
          <p>{t("Tour.Step4.SchemaColorDescription")}</p>
          <br />
          <h3>{t("Tour.Step4.Logo")}</h3>
          <br />
          <p>{t("Tour.Step4.LogoDescription")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-menu",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step5.Menu")}</h2>
          <p>{t("Tour.Step5.SectionIntro")}</p>
          <br />
          <h3>{t("Tour.Step5.DaysAndTime")}</h3>
          <br />
          <p>{t("Tour.Step5.DaysAndTimeDescription")}</p>
          <br />
          <p>{t("Tour.Step5.Optional")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-category",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step6.Category")}</h2>
          <p>{t("Tour.Step6.SectionIntro")}</p>
          <br />
          <p>{t("Tour.Step6.Description")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-product",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step7.Product")}</h2>
          <p>{t("Tour.Step7.SectionIntro")}</p>
          <br />
          <p>{t("Tour.Step7.Description")}</p>
          <br />
          <p>{t("Tour.Step7.ExtraDescription")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-qrcode",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step8.QRCode")}</h2>
          <p>{t("Tour.Step8.SectionIntro")}</p>
          <br />
          <p>{t("Tour.Step8.Description")}</p>
          <br />
          <p>{t("Tour.Step8.ExtraDescription")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      selector: ".tour-availability",
      content: (
        <div className="tour-container">
          <h2>{t("Tour.Step9.Availability")}</h2>
          <p>{t("Tour.Step9.SectionIntro")}</p>
          <br />
          <p>{t("Tour.Step9.Description")}</p>
          <br />
          <p>{t("Tour.LetsContinue")}</p>
        </div>
      ),
      position: "center",
    },
    {
      content: (
        <div className="tour-container">
          <h2>{t("Tour.StepFinal.Title")}</h2>
          <p>{t("Tour.StepFinal.SectionIntro")}</p>
          <br />
          <p>{t("Tour.StepFinal.ContactUs")}</p>
          <br />
          <p>{t("Tour.StepFinal.Enjoy")}</p>
        </div>
      ),
      position: "center",
    },
  ];
};
